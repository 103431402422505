global-navigation-account {
	position: fixed;
	right: 0px;
	z-index: 1;

	.navigation {

		&.is-hidden {
			display: none;
		}

		&.logged-out {

			.button {
				background-color: #930807;
				text-align: center;
				min-width: 100px;
				color: white;
				padding: 10px 20px;
				border-radius: 3px;
				font-size: 12px;
				font-weight: 300;
				box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.75);
				cursor: pointer;
				transition: background-color .3s, color .3s;
				margin-right: 55px;
				margin-top: 25px;

				&:hover {
					background-color: darken(#930807, 5%);
				}
			}
		}
	}

	@media screen and (max-width: 720px) {
		right: initial;
		bottom: 0px;
		background-color: #121317;
		width: 100vw;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;

		.navigation.logged-out {

			.button {
				margin: 0px;
			}
		}
	}
}