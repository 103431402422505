/*-----------------------------------*\
		BASE
\*-----------------------------------*/
/*-----------------------------------------------------*\

	*Filename:		base.scss
	*Description: 	contains the baseline reset and
					grids for the project
	*Version:		1.0.0(2015-03-10)
	*Website:		http://schonne.com
	*Author:		Schonne Eldridge

 ===TABLE OF CONTENTS:===================================

	$_RESET			reset.css

\*------------------------------------------------------*/
/*-----------------------------------*\
		$_RESET
\*-----------------------------------*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

html {
  min-height: 100vh;
}

body {
  line-height: 1;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, *:before, *:after {
  margin: 0;
  padding: 0;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.content {
  width: 1600px;
  margin: auto;
  display: block;
}

@media screen and (max-width: 1600px) {
  .content {
    width: 100%;
  }
}

input:focus, select:focus, textarea:focus, button:focus {
  outline: none;
}

/*-----------------------------------------------------*\

	*Filename:		base.scss
	*Description: 	contains the baselines specifically
					for the project
	*Version:		1.0.0(2015-03-10)
	*Website:		http://schonne.com
	*Author:		Schonne Eldridge

 ===TABLE OF CONTENTS:===================================

	$_FONTS					dynamic fonts
	$_PROJECT BASELINE		baselines for the project suc

\*------------------------------------------------------*/
/*-----------------------------------*\
		$_FONTS
\*-----------------------------------*/
/*-----------------------------------*\
		$_PROJECT BASELINE
\*-----------------------------------*/
body, html, main {
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  background-color: #fff;
  height: auto;
  overflow-x: hidden;
}

main {
  width: 100vw;
}

section.left-panel {
  width: 250px;
  display: flex;
  flex-direction: column;
  background-color: #930807;
  color: white;
  min-height: 100vh;
}

ul.social {
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.social li {
  margin-right: 8px;
  margin-left: 8px;
}

ul.social li a {
  transition: opacity .3s;
}

ul.social li a:hover {
  opacity: .6;
}

section.right-panel {
  width: calc(100vw - 400px);
  display: flex;
}

section.right-panel .main-layout {
  display: flex;
  padding-top: 50px;
  padding-left: 50px;
  padding-bottom: 100px;
}

section.right-panel .side-panel {
  min-width: 250px;
  margin-right: 50px;
  text-align: center;
  min-height: 100vh;
  margin-left: auto;
  margin-right: 0px;
}

select {
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: .6em 1.4em .5em .8em;
  font-family: 'Montserrat', sans-serif;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: .5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"), linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
}

select::-ms-expand {
  display: none;
}

select:hover {
  border-color: #888;
}

select:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

select option {
  font-weight: normal;
}

@media screen and (max-width: 720px) {
  main {
    margin-top: 50px;
    margin-bottom: 48px;
    min-height: 100vh;
  }
}

/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

/*
* Scrollbar rail styles
*/
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
}

.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x, .ps:hover > .ps__rail-y {
  opacity: 0.6;
}

.ps--focus > .ps__rail-x, .ps--focus > .ps__rail-y {
  opacity: 0.6;
}

.ps--scrolling-x > .ps__rail-x, .ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
  background-color: #eee;
  opacity: 0.9;
}

/*
* Scrollbar thumb styles
*/
.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  -webkit-transition: background-color .2s linear, height .2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__thumb-y {
  background-color: rgba(44, 45, 48, 0.52);
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  width: 8px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x, .ps__rail-x:focus > .ps__thumb-x, .ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 11px;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

/*-----------------------------------*\
		CUSTOM ELEMENTS
\*-----------------------------------*/
loading-element {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  perspective: 800px;
}

loading-element .inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

loading-element .inner.one {
  left: 0%;
  top: 0%;
  animation: rotate-one 1s linear infinite;
  border-bottom: 3px solid #666;
}

loading-element .inner.two {
  right: 0%;
  top: 0%;
  animation: rotate-two 1s linear infinite;
  border-right: 3px solid #666;
}

loading-element .inner.three {
  right: 0%;
  bottom: 0%;
  animation: rotate-three 1s linear infinite;
  border-top: 3px solid #666;
}

loading-element.white .inner.one {
  border-bottom: 3px solid #EFEFFA;
}

loading-element.white .inner.two {
  border-right: 3px solid #EFEFFA;
}

loading-element.white .inner.three {
  border-top: 3px solid #EFEFFA;
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

global-navigation {
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  background-color: #121317;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  padding-top: 25px;
  transition: none;
}

global-navigation .menu-buttons {
  display: none;
  position: relative;
  right: calc(50% - 25px);
  top: 18px;
}

global-navigation svg.logo {
  margin-bottom: 15px;
  cursor: pointer;
  opacity: 0;
  transform: scale(4) translate(0px, calc(10vh - 25px));
  transition: none;
}

global-navigation svg.logo:hover {
  opacity: .8;
}

global-navigation .navigation {
  display: flex;
  margin-left: 0px;
  margin-right: 0px;
}

global-navigation .navigation li {
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-25px, 0px);
  opacity: 0;
}

global-navigation .navigation li .avatar {
  width: 25px;
  height: 25px;
  display: inline-block;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: 1px solid black;
  cursor: pointer;
  transition: opacity .3s;
}

global-navigation .navigation li .avatar:hover {
  opacity: .7;
}

global-navigation .navigation li img {
  max-width: 18px;
  max-height: 18px;
  cursor: pointer;
  transition: opacity .3s;
}

global-navigation .navigation li img:hover {
  opacity: .3;
}

global-navigation .navigation.is-hidden {
  display: none;
}

global-navigation .navigation.global {
  flex-direction: column;
  color: #7c919e;
  font-size: 14px;
  text-align: left;
  margin-top: 25px;
  align-items: baseline;
  width: 110px;
  margin-bottom: 10px;
}

global-navigation .navigation.global li {
  margin-bottom: 15px;
  cursor: pointer;
  transition: color .3s;
}

global-navigation .navigation.global li:hover {
  color: #d9d9d9;
}

global-navigation .navigation.global.small li {
  font-size: 10px;
}

global-navigation hr {
  width: 100px;
  border-top: 1px solid #7c919e;
  border-bottom: none;
  display: none;
}

global-navigation .button {
  background-color: #930807;
  text-align: center;
  min-width: 100px;
  color: white;
  padding: 3px 5px;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.75);
  cursor: pointer;
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  display: none;
}

global-navigation .button:hover {
  background-color: #7b0706;
}

global-navigation.is-animating {
  transform: translate(calc(-100vw + 151px), 0px);
  transition: transform 1s 4s;
}

global-navigation.is-animating svg.logo {
  opacity: 1;
  transform: translate(calc(50vw - 76px), 0px) scale(1);
  transition: transform 1s 4s, opacity 3s 1s;
}

global-navigation.is-ready {
  width: 150px;
  transform: translate(0px, 0px);
  transition: none;
}

global-navigation.is-ready svg.logo {
  transform: translate(0px, 0px);
  transition: none;
  opacity: 1;
}

global-navigation.is-ready .navigation li {
  transform: translate(0px, 0px);
  opacity: 1;
}

global-navigation.is-ready .navigation li:nth-child(1) {
  transition: transform .5s 0s, opacity .8s 0s;
}

global-navigation.is-ready .navigation li:nth-child(2) {
  transition: transform .5s .2s, opacity .8s .2s;
}

global-navigation.is-ready .navigation li:nth-child(3) {
  transition: transform .5s .4s, opacity .8s .4s;
}

global-navigation.is-ready .navigation li:nth-child(4) {
  transition: transform .5s .6s, opacity .8s .6s;
}

global-navigation.is-ready .navigation li:nth-child(5) {
  transition: transform .5s .8s, opacity .8s .8s;
}

global-navigation.is-ready.is-hidden {
  transform: translate(-150px, 0px);
  transition: transform .5s;
}

global-navigation.is-ready.is-hidden svg.logo {
  transform: scale(0.6) translate(176px, -57px);
  transition: transform .5s;
}

@media screen and (max-width: 720px) {
  global-navigation svg.logo {
    transform: scale(2.5) translate(0px, calc(15vh - 25px));
  }
  global-navigation.is-animating {
    transform: translate(0px, calc(-100vh + 50px));
    transition: transform 1s 4s;
  }
  global-navigation.is-animating svg.logo {
    opacity: 1;
    transform: translate(0px, calc(100vh - 89px)) scale(0.625);
    transition: transform 1s 4s, opacity 3s 1s;
  }
  global-navigation.is-ready {
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    width: 100vw;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
    transition: height .3s, max-height .3s;
  }
  global-navigation.is-ready .menu-buttons {
    display: inline-block;
  }
  global-navigation.is-ready .menu-buttons img.close {
    display: none;
  }
  global-navigation.is-ready svg.logo {
    position: absolute;
    width: 50px;
    height: 50px;
    transform: translate(0px, 0px);
    margin-bottom: 0px;
    transition: transform 0s;
    opacity: 1;
  }
  global-navigation.is-ready .menu.global {
    transform: translate(0px, 75%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  global-navigation.is-ready .menu.global li {
    margin-bottom: 25%;
    transform: translate(-25px, 0px);
    opacity: 0;
  }
  global-navigation.is-ready .navigation.global {
    font-size: 20px;
    align-items: center;
    margin: 0px;
    padding-bottom: 25%;
    margin-bottom: 25%;
    border-bottom: 1px solid #383838;
  }
  global-navigation.is-ready .navigation.global.small {
    padding-top: 25%;
    border-bottom: 0px;
    margin-bottom: 0px;
  }
  global-navigation.is-ready .navigation.global.small li {
    font-size: 16px;
  }
  global-navigation.is-ready.is-open {
    max-height: calc(100vh - 50px);
    height: calc(100vh - 50px);
    transition: height .3s, max-height .3s;
  }
  global-navigation.is-ready.is-open .menu-buttons img.close {
    display: inline-block;
  }
  global-navigation.is-ready.is-open .menu-buttons img.hamburger {
    display: none;
  }
  global-navigation.is-ready.is-open svg.logo {
    transform: scale(2) translate(0px, 100%);
    transition: transform 1s;
  }
  global-navigation.is-ready.is-open .navigation li {
    transform: translate(0px, 0px);
    opacity: 1;
  }
  global-navigation.is-ready.is-open .navigation li:nth-child(1) {
    transition: transform .5s .3s, opacity .8s .3s;
  }
  global-navigation.is-ready.is-open .navigation li:nth-child(2) {
    transition: transform .5s .4s, opacity .8s .4s;
  }
  global-navigation.is-ready.is-open .navigation li:nth-child(3) {
    transition: transform .5s .5s, opacity .8s .5s;
  }
  global-navigation.is-ready.is-open .navigation li:nth-child(4) {
    transition: transform .5s .8s, opacity .8s .8s;
  }
  global-navigation.is-ready.is-open .navigation li:nth-child(5) {
    transition: transform .5s .9s, opacity .8s .9s;
  }
}

@media screen and (max-height: 750px) {
  global-navigation.is-ready .menu.global {
    transform: translate(0px, 54%);
  }
}

@media screen and (max-height: 625px) {
  global-navigation.is-ready .menu.global {
    transform: translate(0px, 35%);
  }
  global-navigation.is-ready.is-open svg.logo {
    transform: scale(2) translate(0px, 50%);
  }
}

@media screen and (max-height: 565px) {
  global-navigation.is-ready .menu.global {
    transform: translate(0px, 40%);
  }
  global-navigation.is-ready .navigation.global {
    padding-bottom: 15%;
    margin-bottom: 15%;
  }
  global-navigation.is-ready .navigation.global.small {
    padding-top: 15%;
  }
}

global-navigation-account {
  position: fixed;
  right: 0px;
  z-index: 1;
}

global-navigation-account .navigation.is-hidden {
  display: none;
}

global-navigation-account .navigation.logged-out .button {
  background-color: #930807;
  text-align: center;
  min-width: 100px;
  color: white;
  padding: 10px 20px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 300;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.75);
  cursor: pointer;
  transition: background-color .3s, color .3s;
  margin-right: 55px;
  margin-top: 25px;
}

global-navigation-account .navigation.logged-out .button:hover {
  background-color: #7b0706;
}

@media screen and (max-width: 720px) {
  global-navigation-account {
    right: initial;
    bottom: 0px;
    background-color: #121317;
    width: 100vw;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  global-navigation-account .navigation.logged-out .button {
    margin: 0px;
  }
}

global-notifications {
  display: block;
  min-height: 100vh;
  padding-left: 150px;
}

global-messages {
  min-height: 100vh;
  display: flex;
  background-image: url(/assets/images/deletethis_background.jpg);
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
  padding-top: 50px;
}

global-search {
  display: block;
  min-height: 100vh;
  padding-left: 150px;
}

global-loginjoin {
  background-color: #F4F4F4;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

factory-profile {
  min-height: 100vh;
  display: flex;
  background-color: white;
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
}

factory-profile section.profile {
  width: 150px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 25px;
  background-color: rgba(255, 255, 255, 0.43);
  position: fixed;
  background-color: #121317;
}

factory-profile section.profile .factory-avatar {
  width: 100px;
  height: 100px;
  display: inline-block;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  border: 1px double #000;
  outline-offset: 1px;
  margin-bottom: 15px;
  opacity: 0;
  transition: opacity .8s 0s;
}

factory-profile section.profile .factory-avatar:after {
  content: "";
  height: 108px;
  width: 108px;
  border-radius: 5px;
  border: 2px solid #000;
  display: inline-block;
  top: -5px;
  left: -5px;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
}

factory-profile section.profile .factory-name {
  font-weight: 700;
  letter-spacing: -1px;
  margin-bottom: 5px;
  font-size: 18px;
  text-align: center;
  color: white;
  transform: translate(-25px, 0px);
  transition: transform .5s .2s, opacity .8s .2s;
  opacity: 0;
}

factory-profile section.profile .member-count {
  color: grey;
  font-size: 14px;
  margin-left: 2px;
  letter-spacing: -.5px;
  font-weight: 600;
  margin-bottom: 6px;
  cursor: pointer;
  transition: color .3s;
  transform: translate(-25px, 0px);
  transition: transform .5s .4s, opacity .8s .4s;
  opacity: 0;
}

factory-profile section.profile .member-count span {
  font-weight: 300;
}

factory-profile section.profile .member-count:hover {
  color: #9a9a9a;
}

factory-profile section.profile .button {
  background-color: #930807;
  color: white;
  padding: 3px 5px;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.75);
  cursor: pointer;
  transition: background-color .3s;
  display: inline-block;
  transform: translate(-25px, 0px);
  transition: transform .5s .6s, opacity .8s .6s;
  opacity: 0;
}

factory-profile section.profile .button:hover {
  background-color: #7b0706;
}

factory-profile section.profile hr {
  width: 0%;
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: .5px solid #d2d2d2;
  border-top: .5px solid #d2d2d2;
  opacity: 0;
  transition: width .5s .4s, opacity 0s .4s;
}

factory-profile section.profile .factory-bottom {
  min-height: calc(100vh - 250px);
}

factory-profile section.profile .factory-top .back-arrow {
  display: none;
}

factory-profile section.profile ul.factory-navigation li, factory-profile section.profile ul.member-navigation li, factory-profile section.profile ul.factory-help li {
  color: #7c919e;
  font-size: 14px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: color .3s;
  transform: translate(-25px, 0px);
  opacity: 0;
}

factory-profile section.profile ul.factory-navigation li:last-child, factory-profile section.profile ul.member-navigation li:last-child, factory-profile section.profile ul.factory-help li:last-child {
  margin-bottom: 0;
}

factory-profile section.profile ul.factory-navigation li.is-selected, factory-profile section.profile ul.member-navigation li.is-selected, factory-profile section.profile ul.factory-help li.is-selected {
  font-weight: 700;
  color: white;
}

factory-profile section.profile ul.factory-navigation li:hover, factory-profile section.profile ul.member-navigation li:hover, factory-profile section.profile ul.factory-help li:hover {
  color: #d9d9d9;
}

factory-profile section.profile ul.factory-help {
  position: absolute;
  bottom: 50px;
}

factory-profile section.factory-experience {
  min-width: calc(100% - 150px);
  min-height: calc(100% - 50px);
  margin-left: 150px;
}

factory-profile.is-ready section.profile .factory-avatar {
  opacity: 1;
}

factory-profile.is-ready section.profile .factory-name, factory-profile.is-ready section.profile .member-count, factory-profile.is-ready section.profile .button {
  opacity: 1;
  transform: translate(0px, 0px);
}

factory-profile.is-ready section.profile hr {
  width: 80%;
  opacity: 1;
}

factory-profile.is-ready section.profile ul.factory-navigation li, factory-profile.is-ready section.profile ul.member-navigation li, factory-profile.is-ready section.profile ul.factory-help li {
  opacity: 1;
  transform: translate(0px, 0px);
}

factory-profile.is-ready section.profile ul.factory-navigation li:nth-child(1), factory-profile.is-ready section.profile ul.member-navigation li:nth-child(1), factory-profile.is-ready section.profile ul.factory-help li:nth-child(1) {
  transition: transform .5s .2s, opacity .8s .2s;
}

factory-profile.is-ready section.profile ul.factory-navigation li:nth-child(2), factory-profile.is-ready section.profile ul.member-navigation li:nth-child(2), factory-profile.is-ready section.profile ul.factory-help li:nth-child(2) {
  transition: transform .5s .3s, opacity .8s .3s;
}

factory-profile.is-ready section.profile ul.factory-navigation li:nth-child(3), factory-profile.is-ready section.profile ul.member-navigation li:nth-child(3), factory-profile.is-ready section.profile ul.factory-help li:nth-child(3) {
  transition: transform .5s .4s, opacity .8s .4s;
}

factory-profile.is-ready section.profile ul.factory-navigation li:nth-child(4), factory-profile.is-ready section.profile ul.member-navigation li:nth-child(4), factory-profile.is-ready section.profile ul.factory-help li:nth-child(4) {
  transition: transform .5s .5s, opacity .8s .5s;
}

factory-profile.is-ready section.profile ul.factory-navigation li:nth-child(5), factory-profile.is-ready section.profile ul.member-navigation li:nth-child(5), factory-profile.is-ready section.profile ul.factory-help li:nth-child(5) {
  transition: transform .5s .6s, opacity .8s .6s;
}

factory-profile.is-ready section.profile ul.factory-navigation li:nth-child(6), factory-profile.is-ready section.profile ul.member-navigation li:nth-child(6), factory-profile.is-ready section.profile ul.factory-help li:nth-child(6) {
  transition: transform .5s .7s, opacity .8s .7s;
}

factory-profile.is-ready section.profile ul.factory-navigation li:nth-child(7), factory-profile.is-ready section.profile ul.member-navigation li:nth-child(7), factory-profile.is-ready section.profile ul.factory-help li:nth-child(7) {
  transition: transform .5s .8s, opacity .8s .8s;
}

factory-profile.is-ready section.profile ul.factory-navigation li:nth-child(8), factory-profile.is-ready section.profile ul.member-navigation li:nth-child(8), factory-profile.is-ready section.profile ul.factory-help li:nth-child(8) {
  transition: transform .5s .9s, opacity .8s .9s;
}

@media screen and (max-width: 720px) {
  factory-profile {
    height: 50px;
    max-height: 50px;
    min-height: 50px;
  }
  factory-profile section.profile {
    position: fixed;
    width: 100vw;
    top: 0px;
    height: 50px;
    padding: 0px;
    z-index: 1;
    height: 100px;
    max-height: 100px;
    min-height: 100px;
  }
  factory-profile section.profile hr {
    display: none;
  }
  factory-profile section.profile .factory-top {
    width: 100vw;
    display: flex;
    max-height: 50px;
    padding-left: 3%;
    padding-right: 5%;
    align-items: center;
  }
  factory-profile section.profile .factory-top .back-arrow {
    margin-right: 20px;
    display: inline-block;
  }
  factory-profile section.profile .factory-top .factory-avatar {
    height: 40px;
    width: 40px;
    margin-bottom: 0px;
    margin-right: 10px;
  }
  factory-profile section.profile .factory-top .factory-avatar:after {
    height: 48px;
    width: 48px;
  }
  factory-profile section.profile .factory-top .factory-info {
    display: flex;
    flex-direction: column;
    height: 50px;
    justify-content: center;
  }
  factory-profile section.profile .factory-top .factory-info .factory-name {
    font-weight: 700;
  }
  factory-profile section.profile .factory-top .button {
    margin-right: 0px;
    margin-left: auto;
    align-self: center;
  }
  factory-profile section.profile .factory-bottom {
    background-color: #1b1c22;
    width: 100vw;
    display: flex;
    align-items: center;
    height: 50px;
    overflow: scroll;
    padding-left: 5%;
    min-height: 50px;
  }
  factory-profile section.profile .factory-bottom ul {
    display: flex;
    border-left: 1px solid white;
  }
  factory-profile section.profile .factory-bottom ul li {
    margin-bottom: 0px;
    margin-right: 30px;
  }
  factory-profile section.profile .factory-bottom ul li:first-child {
    padding-left: 25px;
  }
  factory-profile section.profile .factory-bottom ul.factory-help {
    position: relative;
    bottom: initial;
    left: initial;
  }
  factory-profile section.profile .factory-bottom ul:first-child {
    border-left: none;
  }
  factory-profile section.profile .factory-bottom ul:first-child li {
    padding-left: 0px;
  }
  factory-profile section.factory-experience {
    margin-top: 50px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 720px) {
  body[page="factory-profile"] global-navigation.is-ready.is-hidden {
    transform: translate(0px, -50px);
  }
}

factory-about {
  display: flex;
}

factory-about section.left-panel {
  transform: translate(0%, -100%);
  transition: transform .5s .3s;
}

factory-about section.left-panel .profile-banner {
  height: 450px;
  background-size: cover;
  background-position: center;
  position: relative;
  width: 250px;
  box-shadow: inset 0 0 100px black;
  opacity: 0;
  transition: opacity 1s .6s;
}

factory-about section.left-panel .profile-facts {
  padding: 5%;
}

factory-about section.left-panel .profile-facts .profile-name {
  font-size: 22px;
  text-align: center;
  font-weight: 900;
  margin-bottom: 16px;
  opacity: 0;
  transition: opacity 1s .8s;
}

factory-about section.left-panel .profile-facts .profile-summary {
  font-size: 14px;
  line-height: 1.2;
  padding-bottom: 8px;
  opacity: 0;
  transition: opacity 1s .8s;
}

factory-about section.left-panel .profile-facts .profile-summary p {
  margin-bottom: 16px;
  color: rgba(255, 255, 255, 0.7);
  opacity: 0;
  transition: opacity 1s .9s;
}

factory-about section.left-panel .profile-facts ul.social {
  border-top: 1px solid rgba(255, 255, 255, 0.34);
  border-bottom: 1px solid rgba(255, 255, 255, 0.34);
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 50px;
  width: 0px;
  opacity: 0;
  transition: width .3s 1s;
}

factory-about section.left-panel .profile-facts ul.social li {
  opacity: 0;
}

factory-about section.left-panel .profile-facts img.profile-video {
  margin-bottom: 10px;
  margin-top: 10px;
  width: 35px;
  height: 35px;
  cursor: pointer;
  transition: opacity .3s;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

factory-about section.left-panel .profile-facts img.profile-video:hover {
  opacity: .6;
}

factory-about section.left-panel .profile-facts .profile-stats {
  display: flex;
  flex-direction: column;
}

factory-about section.left-panel .profile-facts .profile-stats label {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 8px;
}

factory-about section.left-panel .profile-facts .profile-stats a, factory-about section.left-panel .profile-facts .profile-stats p {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-size: 14px;
  display: inline-block;
  align-self: start;
}

factory-about section.left-panel .profile-facts .profile-stats a p.title, factory-about section.left-panel .profile-facts .profile-stats p p.title {
  text-transform: uppercase;
  font-size: 10px;
  margin-top: 2px;
  color: rgba(255, 255, 255, 0.5);
  display: block;
}

factory-about section.right-panel {
  background-color: #f4f4f4;
}

factory-about section.right-panel .main-layout {
  width: 100%;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 2%;
  display: flex;
  flex-direction: column;
}

factory-about section.right-panel .main-layout .current-status {
  background-color: #121317;
  width: 98%;
  padding: 2%;
  padding-bottom: 1%;
  align-self: self-end;
  margin-bottom: 2%;
  border-bottom-right-radius: 4px;
  transform: translate(0%, -100%);
  transition: transform .5s .3s;
}

factory-about section.right-panel .main-layout .current-status h2 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 16px;
  color: white;
}

factory-about section.right-panel .main-layout .current-status .status-container {
  display: flex;
  flex-direction: column;
}

factory-about section.right-panel .main-layout .current-status .status-container .status-update {
  opacity: 0;
  transition: opacity .5s .8s;
}

factory-about section.right-panel .main-layout .current-status .status-container .status-update .status-date {
  font-size: 12px;
  color: grey;
  font-weight: bold;
  margin-bottom: 6px;
}

factory-about section.right-panel .main-layout .current-status .status-container .status-update .status {
  line-height: 1.3;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

factory-about section.right-panel .main-layout .current-status .status-container .promotions {
  margin-top: 8px;
  padding-top: 8px;
  border-top: solid #dbdbdb 1px;
  display: flex;
  opacity: 0;
  transition: opacity .5s .8s;
}

factory-about section.right-panel .main-layout .current-status .status-container .promotions .promotion {
  border-radius: 4px;
  display: flex;
  padding: 1%;
  max-width: 32%;
  margin-right: 1.3333333%;
}

factory-about section.right-panel .main-layout .current-status .status-container .promotions .promotion .promotion-image {
  width: 100px;
  height: 100px;
  background-size: cover;
  margin-right: 8px;
}

factory-about section.right-panel .main-layout .current-status .status-container .promotions .promotion .promotion-description {
  width: calc(100% - 100px);
}

factory-about section.right-panel .main-layout .current-status .status-container .promotions .promotion .promotion-description .promotion-title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
  color: white;
}

factory-about section.right-panel .main-layout .current-status .status-container .promotions .promotion .promotion-description .promotion-text {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
}

factory-about section.right-panel .main-layout .current-status .status-container .promotions .promotion .promotion-description .promotion-button {
  border: #930807 solid 1px;
  text-align: center;
  color: #930807;
  padding: 2px 4px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 300;
  cursor: pointer;
  transition: background-color .3s, color .3s;
  margin-top: 10px;
  display: inline-block;
}

factory-about section.right-panel .main-layout .current-status .status-container .promotions .promotion .promotion-description .promotion-button:hover {
  background-color: red;
}

factory-about section.right-panel .main-layout .current-status .status-container .promotions .promotion:last-child {
  margin-right: 0px;
}

factory-about section.right-panel .main-layout .current-status.horizontal .status-container {
  flex-direction: row;
}

factory-about section.right-panel .main-layout .current-status.horizontal .status-container .status-update {
  width: 40%;
}

factory-about section.right-panel .main-layout .current-status.horizontal .status-container .promotions {
  flex-direction: column;
  width: 60%;
  border-top: none;
  border-left: 1px solid grey;
  margin-top: 0px;
  padding-top: 0px;
  margin-left: 15px;
  padding-left: 15px;
}

factory-about section.right-panel .main-layout .current-status.horizontal .status-container .promotions .promotion {
  max-width: 100%;
  margin-right: 0px;
  margin-bottom: 10px;
}

factory-about section.right-panel .main-layout .current-status.horizontal .status-container .promotions .promotion .promotion-image {
  width: 60px;
  height: 60px;
}

factory-about section.right-panel .main-layout .current-status.horizontal .status-container .promotions .promotion .promotion-title {
  margin-bottom: 5px;
}

factory-about section.right-panel .main-layout .current-status.horizontal .status-container .promotions .promotion .promotion-button {
  padding: 0px;
  border: none;
  font-weight: 700;
  margin-top: 0px;
  transition: color ,3s;
}

factory-about section.right-panel .main-layout .current-status.horizontal .status-container .promotions .promotion .promotion-button:hover {
  color: #4a0404;
  background-color: transparent;
}

factory-about section.right-panel .main-layout .announcements {
  background-color: white;
  border-radius: 4px;
  width: 96%;
  padding: 2%;
  align-self: self-end;
  margin-right: 2%;
  margin-left: 2%;
  opacity: 0;
  transition: opacity 1s .5s;
}

factory-about section.right-panel .main-layout .announcements h2 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 16px;
  opacity: 0;
  transition: opacity 1s 1s;
}

factory-about section.right-panel .main-layout .announcements .announcement .announcement-image {
  background-size: cover;
  width: 100%;
  min-height: 200px;
  margin-bottom: 15px;
  border-radius: 4px;
}

factory-about section.right-panel .main-layout .announcements .announcement .announcement-date {
  font-size: 12px;
  color: grey;
  font-weight: bold;
  margin-bottom: 6px;
}

factory-about section.right-panel .main-layout .announcements .announcement .announcement-date span.announcement-author {
  color: #930807;
  cursor: pointer;
  transition: color .3s;
}

factory-about section.right-panel .main-layout .announcements .announcement .announcement-date span.announcement-author:hover {
  color: #4a0404;
}

factory-about section.right-panel .main-layout .announcements .announcement .announcement-title {
  font-weight: 900;
  font-size: 27px;
  margin-bottom: 8px;
  color: #930807;
  transition: color .3s;
  cursor: pointer;
}

factory-about section.right-panel .main-layout .announcements .announcement .announcement-title:hover {
  color: #4a0404;
}

factory-about section.right-panel .main-layout .announcements .announcement .announcement-text {
  font-size: 14px;
  line-height: 1.3;
}

factory-about section.right-panel .main-layout .announcements .announcements-top {
  display: flex;
  margin-bottom: 50px;
  opacity: 0;
  transition: opacity 1s 1.5s;
}

factory-about section.right-panel .main-layout .announcements .announcements-top .announcement {
  width: 66%;
  margin-right: 1%;
}

factory-about section.right-panel .main-layout .announcements .announcements-top .events {
  width: 33%;
  margin-left: 1%;
  padding-left: 10px;
  background-color: #f4f4f4;
  border-radius: 4px;
  padding-top: 15px;
  padding-right: 15px;
}

factory-about section.right-panel .main-layout .announcements .announcements-top .events .event {
  border-bottom: 1px solid #dadada;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

factory-about section.right-panel .main-layout .announcements .announcements-top .events .event .event-date {
  font-size: 12px;
  color: grey;
  font-weight: bold;
  margin-bottom: 3px;
}

factory-about section.right-panel .main-layout .announcements .announcements-top .events .event .event-title {
  font-weight: 700;
  font-size: 14px;
  color: #930807;
  margin-bottom: 6px;
  cursor: pointer;
  transition: color .3s;
}

factory-about section.right-panel .main-layout .announcements .announcements-top .events .event .event-title:hover {
  color: #4a0404;
}

factory-about section.right-panel .main-layout .announcements .announcements-top .events .event .event-attending {
  font-size: 12px;
  color: grey;
  margin-bottom: 6px;
}

factory-about section.right-panel .main-layout .announcements .announcements-top .events .event:last-child {
  border-bottom: none;
  margin-bottom: none;
}

factory-about section.right-panel .main-layout .announcements .announcements-bottom {
  display: flex;
  opacity: 0;
  transition: opacity .5s 2s;
}

factory-about section.right-panel .main-layout .announcements .announcements-bottom .announcement {
  width: 33.3333%;
  margin-right: 25px;
}

factory-about section.right-panel .main-layout .announcements .announcements-bottom .announcement .announcement-image {
  min-height: 150px;
}

factory-about section.right-panel .main-layout .announcements .announcements-bottom .announcement .announcement-title {
  font-size: 16px;
}

factory-about section.right-panel .main-layout .announcements .announcements-bottom .announcement .announcement-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  line-height: 1.2;
}

factory-about section.right-panel .main-layout .announcements .announcements-bottom .announcement:last-child {
  margin-right: 0px;
}

factory-about section.right-panel .side-panel {
  margin-top: 60px;
  margin-left: 0px;
  margin-right: 0px;
  min-width: 200px;
}

factory-about section.right-panel .side-panel .whos-online {
  text-align: left;
}

factory-about section.right-panel .side-panel .whos-online h2 {
  font-weight: 600;
  font-size: 10px;
  margin-bottom: 10px;
  margin-top: 25px;
  text-align: left;
  text-transform: uppercase;
  opacity: 0;
  transition: opacity .5s 1s;
}

factory-about section.right-panel .side-panel .whos-online .whos .who {
  display: flex;
  border-bottom: 1px solid #cbcbcb;
  padding-bottom: 8px;
  margin-bottom: 8px;
  transform: translate(-25px, 0px);
  opacity: 0;
}

factory-about section.right-panel .side-panel .whos-online .whos .who .who-avatar {
  width: 35px;
  height: 35px;
  background-size: cover;
  border-radius: 50%;
  margin-right: 10px;
  background-position: center;
  cursor: pointer;
  transition: opacity .3s;
}

factory-about section.right-panel .side-panel .whos-online .whos .who .who-avatar:hover {
  opacity: .8;
}

factory-about section.right-panel .side-panel .whos-online .whos .who .who-details {
  display: flex;
  flex-direction: column;
}

factory-about section.right-panel .side-panel .whos-online .whos .who .who-details .who-name {
  font-weight: 700;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  transition: color .3s;
}

factory-about section.right-panel .side-panel .whos-online .whos .who .who-details .who-name:hover {
  color: #930807;
}

factory-about section.right-panel .side-panel .whos-online .whos .who .who-details .who-title {
  font-size: 12px;
  text-align: left;
}

factory-about section.right-panel .side-panel .whos-online .whos .who .who-details .who-activity {
  font-size: 12px;
  text-align: left;
  color: #930807;
  font-style: italic;
  cursor: pointer;
  transition: color .3s;
}

factory-about section.right-panel .side-panel .whos-online .whos .who .who-details .who-activity:hover {
  color: #4a0404;
}

factory-about section.right-panel .side-panel .whos-online .whos .who:last-child {
  border-bottom: none;
}

factory-about section.right-panel .side-panel .whos-online .button {
  text-align: center;
  min-width: 100px;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 300;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.75);
  cursor: pointer;
  transition: background-color .3s, color .3s;
  margin-right: 25px;
  background-color: transparent;
  border: solid #121317 1px;
  color: #121317;
  display: inline-block;
  margin-top: 10px;
}

factory-about.is-ready section.left-panel {
  transform: translateY(0%);
}

factory-about.is-ready section.left-panel .profile-banner {
  opacity: 1;
}

factory-about.is-ready section.left-panel .profile-facts .profile-name {
  opacity: 1;
}

factory-about.is-ready section.left-panel .profile-facts .profile-summary {
  opacity: 1;
}

factory-about.is-ready section.left-panel .profile-facts .profile-summary p {
  opacity: 1;
}

factory-about.is-ready section.left-panel .profile-facts ul.social {
  width: 100%;
  opacity: 1;
}

factory-about.is-ready section.left-panel .profile-facts ul.social li:nth-child(1) {
  opacity: 1;
  transition: opacity .3s 1.2s;
}

factory-about.is-ready section.left-panel .profile-facts ul.social li:nth-child(2) {
  opacity: 1;
  transition: opacity .3s 1.3s;
}

factory-about.is-ready section.left-panel .profile-facts ul.social li:nth-child(3) {
  opacity: 1;
  transition: opacity .3s 1.4s;
}

factory-about.is-ready section.left-panel .profile-facts ul.social li:nth-child(4) {
  opacity: 1;
  transition: opacity .3s 1.5s;
}

factory-about.is-ready section.left-panel .profile-facts ul.social li:nth-child(5) {
  opacity: 1;
  transition: opacity .3s 1.6s;
}

factory-about.is-ready section.left-panel .profile-facts ul.social li:nth-child(6) {
  opacity: 1;
  transition: opacity .3s 1.7s;
}

factory-about.is-ready section.left-panel .profile-facts ul.social li:nth-child(7) {
  opacity: 1;
  transition: opacity .3s 1.8s;
}

factory-about.is-ready section.right-panel .main-layout .current-status {
  transform: translateY(0%);
}

factory-about.is-ready section.right-panel .main-layout .current-status .status-container .promotions, factory-about.is-ready section.right-panel .main-layout .current-status .status-container .status-update {
  opacity: 1;
}

factory-about.is-ready section.right-panel .main-layout .announcements {
  opacity: 1;
}

factory-about.is-ready section.right-panel .main-layout .announcements h2 {
  opacity: 1;
}

factory-about.is-ready section.right-panel .main-layout .announcements .announcements-top, factory-about.is-ready section.right-panel .main-layout .announcements .announcements-bottom {
  opacity: 1;
}

factory-about.is-ready section.right-panel .side-panel h2 {
  opacity: 1;
}

factory-about.is-ready section.right-panel .side-panel .whos .who {
  transform: translate(0px, 0px);
  opacity: 1;
  transition: opacity .6s 2s, transform 1s 2s;
}

factory-about.is-ready section.right-panel .side-panel .whos .who:nth-child(1) {
  transition: opacity .6s 1s, transform 1s 1s;
}

factory-about.is-ready section.right-panel .side-panel .whos .who:nth-child(2) {
  transition: opacity .6s 1.1s, transform 1s 1.1s;
}

factory-about.is-ready section.right-panel .side-panel .whos .who:nth-child(3) {
  transition: opacity .6s 1.2s, transform 1s 1.2s;
}

factory-about.is-ready section.right-panel .side-panel .whos .who:nth-child(4) {
  transition: opacity .6s 1.3s, transform 1s 1.3s;
}

factory-about.is-ready section.right-panel .side-panel .whos .who:nth-child(5) {
  transition: opacity .6s 1.4s, transform 1s 1.4s;
}

factory-about.is-ready section.right-panel .side-panel .whos .who:nth-child(6) {
  transition: opacity .6s 1.5s, transform 1s 1.5s;
}

factory-about.is-ready section.right-panel .side-panel .whos .who:nth-child(7) {
  transition: opacity .6s 1.6s, transform 1s 1.6s;
}

factory-about.is-ready section.right-panel .side-panel .whos .who:nth-child(8) {
  transition: opacity .6s 1.7s, transform 1s 1.7s;
}

factory-about.is-ready section.right-panel .side-panel .whos .who:nth-child(9) {
  transition: opacity .6s 1.8s, transform 1s 1.8s;
}

@media screen and (max-width: 1200px) {
  factory-about section.right-panel .main-layout .announcements .announcements-top {
    flex-direction: column;
    margin-bottom: 25px;
  }
  factory-about section.right-panel .main-layout .announcements .announcements-top .announcement {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 25px;
  }
  factory-about section.right-panel .main-layout .announcements .announcements-top .events {
    width: 100%;
    margin-left: 0px;
  }
  factory-about section.right-panel .main-layout .announcements .announcements-bottom {
    flex-direction: column;
  }
  factory-about section.right-panel .main-layout .announcements .announcements-bottom .announcement {
    width: 100%;
    margin-right: 0px;
    display: flex;
  }
  factory-about section.right-panel .main-layout .announcements .announcements-bottom .announcement .announcement-image {
    width: 33%;
    margin-right: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  factory-about section.right-panel .main-layout .announcements .announcements-bottom .announcement .announcement-details {
    width: 67%;
  }
}

@media screen and (max-width: 1024px) {
  factory-about {
    flex-direction: column;
  }
  factory-about section.left-panel {
    width: calc(100vw - 150px);
    min-height: initial;
    flex-direction: initial;
  }
  factory-about section.left-panel .profile-facts {
    padding: 25px;
    margin-top: 50px;
  }
  factory-about section.left-panel .profile-banner {
    min-width: 250px;
  }
  factory-about section.right-panel {
    width: calc(100vw - 150px);
  }
  factory-about section.right-panel .main-layout .current-status.horizontal {
    transform: translate(-100%, 0%);
  }
  factory-about section.right-panel .main-layout .current-status.horizontal .status-container {
    flex-direction: column;
  }
  factory-about section.right-panel .main-layout .current-status.horizontal .status-container .status-update {
    width: 100%;
    margin-bottom: 10px;
  }
  factory-about section.right-panel .main-layout .current-status.horizontal .status-container .promotions {
    width: 100%;
  }
  factory-about section.right-panel .side-panel {
    margin-top: 0px;
  }
  factory-about.is-ready section.right-panel .main-layout .current-status {
    transform: translate(0%, 0%);
  }
}

@media screen and (max-width: 720px) {
  factory-about section.left-panel {
    width: 100vw;
    min-height: initial;
  }
  factory-about section.left-panel .profile-banner {
    width: 100vw;
    height: 200px;
    position: absolute;
  }
  factory-about section.left-panel .profile-facts {
    padding: 3%;
    background: linear-gradient(0deg, #930807 0%, #930807 34%, #121317 83%, rgba(18, 19, 23, 0) 100%);
    margin-top: 144px;
  }
  factory-about section.left-panel .profile-facts .profile-name {
    font-size: 32px;
    margin-bottom: 25px;
  }
  factory-about section.left-panel .profile-facts ul.social {
    margin-bottom: 0px;
  }
  factory-about section.right-panel {
    width: 100vw;
  }
}

@media screen and (max-width: 600px) {
  factory-about section.right-panel {
    flex-direction: column;
  }
  factory-about section.right-panel .main-layout .current-status {
    width: 100%;
  }
  factory-about section.right-panel .side-panel .whos-online {
    display: inline-block;
    margin-right: 25px;
    margin-left: 25px;
  }
  factory-about section.right-panel .side-panel .whos-online .whos {
    display: flex;
    flex-wrap: wrap;
  }
  factory-about section.right-panel .side-panel .whos-online .whos .who {
    margin-right: 10px;
    margin-left: 10px;
    border-bottom: none;
    margin-bottom: 20px;
  }
}

factory-discussions {
  display: flex;
}

factory-discussions section.left-panel {
  width: 225px;
  padding-top: 25px;
}

factory-discussions section.left-panel h4 {
  text-transform: uppercase;
  font-size: 10px;
  opacity: .6;
  margin-top: 25px;
  padding-left: 15px;
}

factory-discussions section.left-panel ul.topics li.topic {
  font-size: 14px;
  line-height: 1;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 2px;
  margin-top: 2px;
  cursor: pointer;
  transition: background-color .3s;
  padding-left: 15px;
  padding-right: 15px;
}

factory-discussions section.left-panel ul.topics li.topic .message-avatar {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.32);
  float: left;
}

factory-discussions section.left-panel ul.topics li.topic .message-name {
  display: inline-block;
}

factory-discussions section.left-panel ul.topics li.topic .message-location {
  text-transform: uppercase;
  font-size: 10px;
  opacity: .6;
  margin-top: 2px;
  display: inline-block;
}

factory-discussions section.left-panel ul.topics li.topic:hover {
  background-color: #840706;
}

factory-discussions section.right-panel {
  width: calc(100vw - 225px);
}

factory-discussions section.right-panel .main-layout {
  padding: 0px;
  display: block;
}

factory-discussions section.right-panel .topic-summary {
  background-color: #e7e7e7;
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  padding-left: 2%;
  padding-right: 2%;
}

factory-discussions section.right-panel .topic-summary .recipient {
  display: flex;
  align-items: flex-end;
  margin-right: 2%;
}

factory-discussions section.right-panel .topic-summary .recipient .recipient-avatar {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.32);
}

factory-discussions section.right-panel .topic-summary .recipient .recipient-details {
  display: flex;
  flex-direction: column;
}

factory-discussions section.right-panel .topic-summary .recipient .recipient-details .recipient-name {
  font-size: 20px;
  font-weight: 900;
}

factory-discussions section.right-panel .topic-summary .recipient .recipient-details .recipient-location {
  text-transform: uppercase;
  font-size: 12px;
  opacity: .6;
  margin-top: 2px;
  display: inline-block;
}

factory-discussions section.right-panel .topic-summary.multiple-recipients {
  padding-left: 5px;
}

factory-discussions section.right-panel .topic-summary.multiple-recipients .recipient {
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #d0cece;
  padding-right: 10px;
  padding-left: 10px;
  transition: background-color .3s;
  border-radius: 5px;
  padding: 6px;
  cursor: pointer;
  margin-right: 0px;
}

factory-discussions section.right-panel .topic-summary.multiple-recipients .recipient .recipient-avatar {
  width: 25px;
  height: 25px;
  margin-bottom: 5px;
}

factory-discussions section.right-panel .topic-summary.multiple-recipients .recipient .recipient-name {
  font-size: 14px;
  font-weight: 600;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

factory-discussions section.right-panel .topic-summary.multiple-recipients .recipient .recipient-location {
  font-size: 11px;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

factory-discussions section.right-panel .topic-summary.multiple-recipients .recipient:last-child {
  border-right: 0px;
}

factory-discussions section.right-panel .topic-summary.multiple-recipients .recipient:hover {
  background-color: #d0cece;
}

factory-discussions section.right-panel .chatroom {
  display: flex;
  height: calc(100vh - 200px);
  overflow: hidden;
  position: relative;
  width: 100%;
  width: calc(100% - 300px);
  flex-direction: column;
}

factory-discussions section.right-panel .chatroom .chat {
  display: flex;
  padding: 8px 0;
  padding-left: 20px;
}

factory-discussions section.right-panel .chatroom .chat .chat-avatar {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.32);
}

factory-discussions section.right-panel .chatroom .chat .chat-header {
  display: flex;
  align-items: center;
}

factory-discussions section.right-panel .chatroom .chat .chat-header .chat-sender {
  font-size: 15px;
  font-weight: 600;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  line-height: 1.1;
}

factory-discussions section.right-panel .chatroom .chat .chat-header .chat-timestamp {
  color: #717274;
  font-size: 12px;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin-left: 5px;
}

factory-discussions section.right-panel .chatroom .chat .chat-body {
  line-height: 1.46668;
  color: #2c2d30;
  display: inline-block;
  width: 100%;
  word-wrap: break-word;
  font-size: 15px;
}

factory-discussions section.right-panel .chatroom .chat .chat-body .callout {
  cursor: pointer;
  background-color: rgba(147, 8, 7, 0.1);
  color: #c20100;
  display: inline-block;
  border-radius: 4px;
  padding-right: 4px;
  padding-left: 4px;
}

factory-discussions section.right-panel .chatroom .divider {
  border-bottom: 0;
  border-top: 1px solid #e8e8e8;
  position: relative;
  margin: 28px 0;
}

factory-discussions section.right-panel .chatroom .divider span {
  background: #fff;
  border-radius: 24px;
  display: inline-block;
  padding: .25rem .75rem;
  position: relative;
  z-index: 201;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
  color: #2c2d30;
  cursor: default;
  font-size: 14px;
}

factory-discussions section.right-panel .chat-input {
  height: 75px;
  background-color: yellowgreen;
  position: absolute;
  bottom: -75px;
  width: calc(100% - 300px);
  display: flex;
}

factory-create {
  display: block;
  min-height: 100vh;
  padding-left: 150px;
}

factory-member-profile {
  display: flex;
}

factory-member-profile section.left-panel {
  transform: translate(0%, -100%);
  transition: transform .5s .3s;
}

factory-member-profile section.left-panel .profile-banner {
  height: 450px;
  background-size: cover;
  background-position: center;
  position: relative;
  width: 250px;
  box-shadow: inset 0 0 100px black;
  opacity: 0;
  transition: opacity 1s .6s;
}

factory-member-profile section.left-panel .profile-facts {
  padding: 0px;
  padding-top: 15px;
  padding: 15px;
  padding-left: 12px;
  margin-bottom: 25px;
}

factory-member-profile section.left-panel .profile-facts .profile-name {
  font-size: 21px;
  text-align: center;
  font-weight: 900;
  opacity: 0;
  transition: opacity 1s .8s;
}

factory-member-profile section.left-panel .profile-facts .profile-location {
  font-size: 13px;
  color: #bbbbbb;
  text-transform: uppercase;
  font-weight: 300;
  text-align: center;
  opacity: 0;
  transition: opacity 1s .9s;
}

factory-member-profile section.left-panel .profile-facts .levels {
  display: flex;
  margin-top: 14px;
  border-top: 1px solid #6d6666;
  padding: 5px;
  border-radius: 4px;
  max-width: 0px;
  transition: max-width 1s 1s;
  overflow: hidden;
}

factory-member-profile section.left-panel .profile-facts .levels > div {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #6d6666;
  overflow: hidden;
  opacity: 0;
  transition: opacity 1s 1.5s;
}

factory-member-profile section.left-panel .profile-facts .levels > div .score {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

factory-member-profile section.left-panel .profile-facts .levels > div .attribute {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 300;
  color: #bbbbbb;
}

factory-member-profile section.left-panel .profile-facts .levels > div:last-child {
  border-right: none;
  opacity: 0;
  transition: opacity 1s 1.6s;
}

factory-member-profile section.left-panel .profile-facts .progress-bar {
  width: 100%;
  height: 15px;
  border-radius: 16px;
  box-shadow: inset 2px 2px 5px 1px #00000080;
  background-color: transparent;
  margin-top: 5px;
  max-width: 0px;
  overflow: hidden;
  transition: max-width 1s .8s;
}

factory-member-profile section.left-panel .profile-facts .progress-bar .progress {
  width: 20%;
  background-color: white;
  height: 15px;
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  box-shadow: inset 2px 2px 5px 1px #00000080;
}

factory-member-profile section.left-panel .profile-facts .points {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  opacity: 0;
  transition: opacity 1s 1.25s;
  margin-bottom: 15px;
}

factory-member-profile section.left-panel .profile-facts .points > div {
  font-size: 12px;
  color: #bbbbbb;
}

factory-member-profile section.left-panel .profile-facts .points > div:first-child {
  margin-left: 5px;
}

factory-member-profile section.left-panel .profile-facts .profile-mastermind {
  margin-left: 12px;
  margin-bottom: 15px;
  margin-top: 5px;
  opacity: 0;
  transition: opacity 1s 1s;
}

factory-member-profile section.left-panel .profile-facts .profile-mastermind .mastermind-title {
  font-weight: 600;
  font-size: 10px;
  text-align: left;
  text-transform: uppercase;
  transition: opacity .5s 1s;
  color: #bbbbbb;
}

factory-member-profile section.left-panel .profile-facts .profile-mastermind .mastermind-link {
  font-size: 15px;
  cursor: pointer;
  transition: color .3s;
}

factory-member-profile section.left-panel .profile-facts .profile-mastermind .mastermind-link:hover {
  color: #bbbbbb;
}

factory-member-profile section.left-panel .profile-facts .profile-mastermind .mastermind-count {
  font-size: 14px;
  color: #bbbbbb;
}

factory-member-profile section.left-panel .profile-facts .profile-dates {
  margin-left: 12px;
  margin-bottom: 0px;
  margin-top: 5px;
  opacity: 0;
  transition: opacity 1s 1.25s;
  margin-bottom: 25px;
}

factory-member-profile section.left-panel .profile-facts .profile-dates .profile-date {
  margin-bottom: 15px;
}

factory-member-profile section.left-panel .profile-facts .profile-dates .profile-date .tag {
  font-weight: 600;
  font-size: 10px;
  text-align: left;
  text-transform: uppercase;
  transition: opacity .5s 1s;
  color: #bbbbbb;
}

factory-member-profile section.left-panel .profile-facts .profile-dates .profile-date .date {
  font-size: 15px;
}

factory-member-profile section.left-panel .profile-facts .profile-stats {
  padding-left: 12px;
  border-top: 1px solid #6d6666;
  padding-top: 25px;
  margin-top: 0px;
  max-width: 0px;
  overflow: hidden;
  transition: max-width 1s 1s;
}

factory-member-profile section.left-panel .profile-facts .profile-stats .stat {
  display: flex;
  margin-bottom: 10px;
  opacity: 0;
  transition: opacity 1s 1s;
}

factory-member-profile section.left-panel .profile-facts .profile-stats .stat .score {
  margin-right: 10px;
  font-size: 20px;
  font-weight: 300;
}

factory-member-profile section.left-panel .profile-facts .profile-stats .stat .description {
  font-size: 14px;
  color: #bbbbbb;
}

factory-member-profile section.right-panel {
  background-color: #f4f4f4;
}

factory-member-profile section.right-panel .main-layout {
  width: 100%;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 2%;
  display: flex;
  flex-direction: column;
}

factory-member-profile section.right-panel .main-layout .current-status {
  background-color: #121317;
  width: 98%;
  padding: 2%;
  padding-bottom: 1%;
  margin-bottom: 2%;
  border-bottom-right-radius: 4px;
  transform: translate(0%, -100%);
  transition: transform .5s .3s;
  min-height: 200px;
}

factory-member-profile section.right-panel .side-panel {
  padding-top: 60px;
  width: 250px;
  min-width: 250px;
  max-width: 250px;
}

factory-member-profile section.right-panel .side-panel h2 {
  font-weight: 600;
  font-size: 10px;
  margin-bottom: 10px;
  margin-top: 25px;
  text-align: left;
  text-transform: uppercase;
  transition: opacity .5s .3s;
  opacity: 0;
}

factory-member-profile section.right-panel .side-panel .achievements {
  display: flex;
  flex-direction: column;
  padding-right: 15px;
}

factory-member-profile section.right-panel .side-panel .achievements .achievement {
  display: flex;
  align-items: self-end;
  max-height: 45px;
  min-height: 0px;
  overflow: hidden;
  border: 1px solid #bbbbbb;
  border-radius: 4px;
  padding: 3px;
  transition: border .3s;
  cursor: pointer;
  transition: max-height .5s, box-shadow .5s, opacity .8s, transform .8s, background-color .3s;
  margin-bottom: 10px;
  opacity: 0;
  transform: translate(-50px, 0px);
}

factory-member-profile section.right-panel .side-panel .achievements .achievement .achievement-images {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

factory-member-profile section.right-panel .side-panel .achievements .achievement .achievement-images .achievement-icon {
  min-width: 25px;
  min-height: 25px;
  display: inline-block;
  margin-right: 10px;
}

factory-member-profile section.right-panel .side-panel .achievements .achievement .achievement-images .achievement-progress {
  font-size: 14px;
}

factory-member-profile section.right-panel .side-panel .achievements .achievement .achievement-description {
  text-align: left;
}

factory-member-profile section.right-panel .side-panel .achievements .achievement .achievement-description .achievement-title {
  font-weight: 700;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  transition: color .3s;
  margin-bottom: 3px;
  transition: color .3s;
}

factory-member-profile section.right-panel .side-panel .achievements .achievement .achievement-description .achievement-details {
  font-size: 12px;
  color: #7d7d7d;
}

factory-member-profile section.right-panel .side-panel .achievements .achievement .achievement-rewards {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
  border-top: 1px solid #cacaca;
  border-bottom: 1px solid #cacaca;
  padding-top: 5px;
  padding-bottom: 5px;
}

factory-member-profile section.right-panel .side-panel .achievements .achievement .achievement-rewards .points {
  font-size: 16px;
}

factory-member-profile section.right-panel .side-panel .achievements .achievement .achievement-rewards .points img {
  width: 16px;
  height: 16px;
  margin-left: 5px;
  position: relative;
  top: 2px;
}

factory-member-profile section.right-panel .side-panel .achievements .achievement .achievement-rewards .reputation {
  font-size: 16px;
}

factory-member-profile section.right-panel .side-panel .achievements .achievement .achievement-rewards .reputation img {
  width: 16px;
  height: 16px;
  margin-left: 5px;
  position: relative;
  top: 2px;
}

factory-member-profile section.right-panel .side-panel .achievements .achievement .achievement-stats {
  padding-top: 5px;
  padding-bottom: 5px;
}

factory-member-profile section.right-panel .side-panel .achievements .achievement .achievement-stats .stat {
  font-size: 12px;
  font-weight: 300;
  color: #7d7d7d;
}

factory-member-profile section.right-panel .side-panel .achievements .achievement:hover {
  border: 1px solid #930807;
  box-shadow: 2px 3px 7px 0px rgba(0, 0, 0, 0.4);
  background-color: white;
}

factory-member-profile section.right-panel .side-panel .achievements .achievement:hover .achievement-description .achievement-title {
  color: #930807;
}

factory-member-profile section.right-panel .side-panel .achievements .achievement.is-showing {
  max-height: 500px;
  border: 1px solid #930807;
  box-shadow: 2px 3px 7px 0px rgba(0, 0, 0, 0.4);
  background-color: white;
}

factory-member-profile section.right-panel .side-panel .achievements .achievement.is-showing .achievement-description .achievement-title {
  color: #930807;
}

factory-member-profile.is-ready section.left-panel {
  transform: translateY(0%);
}

factory-member-profile.is-ready section.left-panel .profile-banner {
  opacity: 1;
}

factory-member-profile.is-ready section.left-panel .profile-facts .profile-name {
  opacity: 1;
}

factory-member-profile.is-ready section.left-panel .profile-facts .profile-location {
  opacity: 1;
}

factory-member-profile.is-ready section.left-panel .profile-facts .levels {
  max-width: 250px;
}

factory-member-profile.is-ready section.left-panel .profile-facts .levels > div {
  opacity: 1;
}

factory-member-profile.is-ready section.left-panel .profile-facts .progress-bar {
  max-width: 250px;
}

factory-member-profile.is-ready section.left-panel .profile-facts .points {
  opacity: 1;
}

factory-member-profile.is-ready section.left-panel .profile-facts .profile-mastermind {
  opacity: 1;
}

factory-member-profile.is-ready section.left-panel .profile-facts .profile-dates {
  opacity: 1;
}

factory-member-profile.is-ready section.left-panel .profile-facts .profile-stats {
  max-width: 250px;
}

factory-member-profile.is-ready section.left-panel .profile-facts .profile-stats .stat {
  opacity: 1;
  transition: opacity .8s 1.25s;
}

factory-member-profile.is-ready section.left-panel .profile-facts .profile-stats .stat:nth-child(1) {
  transition: opacity .8s 1.25s;
}

factory-member-profile.is-ready section.left-panel .profile-facts .profile-stats .stat:nth-child(2) {
  transition: opacity .8s 1.35s;
}

factory-member-profile.is-ready section.left-panel .profile-facts .profile-stats .stat:nth-child(3) {
  transition: opacity .8s 1.45s;
}

factory-member-profile.is-ready section.left-panel .profile-facts .profile-stats .stat:nth-child(4) {
  transition: opacity .8s 1.55s;
}

factory-member-profile.is-ready section.left-panel .profile-facts .profile-stats .stat:nth-child(4) {
  transition: opacity .8s 1.65s;
}

factory-member-profile.is-ready section.right-panel .main-layout .current-status {
  transform: translate(0px, 0px);
}

factory-member-profile.is-ready section.right-panel .side-panel h2 {
  opacity: 1;
}

factory-member-profile.is-ready section.right-panel .side-panel .achievements .achievement {
  transform: translate(0px, 0px);
  opacity: 1;
}

factory-member-profile.is-ready section.right-panel .side-panel .achievements .achievement:nth-child(1) {
  transition: max-height .5s, box-shadow .5s, opacity .8s .5s, transform .5s .5s;
}

factory-member-profile.is-ready section.right-panel .side-panel .achievements .achievement:nth-child(2) {
  transition: max-height .5s, box-shadow .5s, opacity .8s .6s, transform .5s .6s;
}

factory-member-profile.is-ready section.right-panel .side-panel .achievements .achievement:nth-child(3) {
  transition: max-height .5s, box-shadow .5s, opacity .8s .7s, transform .5s .7s;
}

factory-member-profile.is-ready section.right-panel .side-panel .achievements .achievement:nth-child(4) {
  transition: max-height .5s, box-shadow .5s, opacity .8s .8s, transform .5s .8s;
}

factory-member-profile.is-ready section.right-panel .side-panel .achievements .achievement:nth-child(5) {
  transition: max-height .5s, box-shadow .5s, opacity .8s .9s, transform .5s .9s;
}

factory-member-profile.is-ready section.right-panel .side-panel .achievements .achievement:nth-child(6) {
  transition: max-height .5s, box-shadow .5s, opacity .8s 1s, transform .5s 1s;
}

factory-member-profile.is-ready section.right-panel .side-panel .achievements .achievement:nth-child(7) {
  transition: max-height .5s, box-shadow .5s, opacity .8s 1.1s, transform .5s 1.1s;
}

factory-member-profile.is-ready section.right-panel .side-panel .achievements .achievement:nth-child(8) {
  transition: max-height .5s, box-shadow .5s, opacity .8s 1.2s, transform .5s 1.2s;
}

factory-member-profile.is-ready section.right-panel .side-panel .achievements .achievement:nth-child(9) {
  transition: max-height .5s, box-shadow .5s, opacity .8s 1.3s, transform .5s 1.3s;
}

factory-member-profile.is-ready section.right-panel .side-panel .achievements .achievement:nth-child(10) {
  transition: max-height .5s, box-shadow .5s, opacity .8s 1.4s, transform .5s 1.4s;
}

factory-member-profile.is-ready section.right-panel .side-panel .achievements .achievement:nth-child(11) {
  transition: max-height .5s, box-shadow .5s, opacity .8s 1.5s, transform .5s 1.5s;
}

@media screen and (max-width: 1024px) {
  factory-member-profile {
    flex-direction: column;
  }
  factory-member-profile section.left-panel {
    width: calc(100vw - 150px);
    min-height: 450px;
    max-height: 450px;
    flex-direction: initial;
    align-items: stretch;
  }
  factory-member-profile section.left-panel .profile-banner {
    height: auto;
  }
  factory-member-profile section.left-panel .profile-facts {
    padding: 25px;
    margin-top: 50px;
    width: calc(100% - 250px);
  }
  factory-member-profile section.left-panel .profile-facts .levels {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
  }
  factory-member-profile section.left-panel .profile-facts .levels > div .score {
    font-size: 21px;
  }
  factory-member-profile section.left-panel .profile-facts .progress-bar {
    margin-left: auto;
    margin-right: auto;
  }
  factory-member-profile section.left-panel .profile-facts .points {
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
  factory-member-profile section.left-panel .profile-facts .profile-details {
    display: flex;
  }
  factory-member-profile section.left-panel .profile-facts .profile-details .profile-attributes {
    width: 50%;
    text-align: right;
    padding-right: 15px;
  }
  factory-member-profile section.left-panel .profile-facts .profile-details .profile-attributes .mastermind-title, factory-member-profile section.left-panel .profile-facts .profile-details .profile-attributes .tag {
    text-align: right;
  }
  factory-member-profile section.left-panel .profile-facts .profile-details .profile-attributes .profile-mastermind, factory-member-profile section.left-panel .profile-facts .profile-details .profile-attributes .profile-dates .profile-date {
    margin-bottom: 25px;
  }
  factory-member-profile section.left-panel .profile-facts .profile-details .profile-attributes .profile-mastermind:last-child, factory-member-profile section.left-panel .profile-facts .profile-details .profile-attributes .profile-dates .profile-date:last-child {
    margin-bottom: 0px;
  }
  factory-member-profile section.left-panel .profile-facts .profile-details .profile-stats {
    width: 50%;
    border-top: none;
    padding-top: 0px;
    border-left: 1px solid #6d6666;
    padding-left: 15px;
    max-height: 0px;
    transition: max-height .8s 1s;
    overflow: hidden;
  }
  factory-member-profile section.left-panel .profile-facts .profile-details .profile-stats .stat {
    margin-bottom: 10px;
  }
  factory-member-profile section.left-panel .profile-facts .profile-details .profile-stats .stat:last-child {
    margin-bottom: 0px;
  }
  factory-member-profile section.left-panel .profile-banner {
    min-width: 250px;
  }
  factory-member-profile section.right-panel {
    width: calc(100vw - 150px);
  }
  factory-member-profile section.right-panel .main-layout .current-status {
    transform: translate(-100%, 0%);
  }
  factory-member-profile section.right-panel .main-layout .current-status .status-container {
    flex-direction: column;
  }
  factory-member-profile section.right-panel .main-layout .current-status .status-container .status-update {
    width: 100%;
    margin-bottom: 10px;
  }
  factory-member-profile section.right-panel .main-layout .current-status .status-container .promotions {
    width: 100%;
  }
  factory-member-profile section.right-panel .side-panel {
    padding-top: 0px;
    margin-top: 0px;
    min-width: 200px;
    max-width: 200px;
  }
  factory-member-profile.is-ready section.right-panel .main-layout .current-status {
    transform: translate(0%, 0%);
  }
  factory-member-profile.is-ready section.left-panel .profile-facts .profile-details .profile-stats {
    max-height: 300px;
  }
}

@media screen and (max-width: 720px) {
  factory-member-profile section.left-panel {
    width: 100vw;
    min-height: initial;
  }
  factory-member-profile section.left-panel .profile-banner {
    width: 100vw;
    height: 200px;
    position: absolute;
    background-position: center 25%;
  }
  factory-member-profile section.left-panel .profile-facts {
    padding: 3%;
    background: linear-gradient(0deg, #930807 0%, #930807 34%, #121317 83%, rgba(18, 19, 23, 0) 100%);
    margin-top: 133px;
    width: 100%;
  }
  factory-member-profile section.left-panel .profile-facts .profile-name {
    font-size: 32px;
  }
  factory-member-profile section.left-panel .profile-facts ul.social {
    margin-bottom: 0px;
  }
  factory-member-profile section.right-panel {
    width: 100vw;
    flex-direction: column;
  }
  factory-member-profile section.right-panel .main-layout .current-status {
    width: 100%;
    border-radius: 0px;
  }
  factory-member-profile section.right-panel .side-panel {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    min-height: auto;
  }
  factory-member-profile section.right-panel .side-panel h2 {
    margin-top: 0px;
  }
  factory-member-profile section.right-panel .side-panel .achievements .achievement {
    margin-bottom: 20px;
    max-height: 50px;
  }
}

member-profile {
  min-height: 100vh;
  display: flex;
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
}

member-profile section.profile {
  width: 150px;
  min-height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 25px;
  border-right: solid 1px lightgray;
  background-color: rgba(255, 255, 255, 0.43);
}

member-profile section.profile .member-avatar {
  width: 100px;
  height: 100px;
  display: inline-block;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  border: 1px double #000;
  outline-offset: 1px;
  margin-bottom: 15px;
}

member-profile section.profile .member-avatar:after {
  content: "";
  height: 108px;
  width: 108px;
  border-radius: 5px;
  border: 2px solid #000;
  display: inline-block;
  top: -5px;
  left: -5px;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
}

member-profile section.profile .member-name {
  font-weight: 900;
  letter-spacing: -1px;
  margin-bottom: 5px;
  font-size: 18px;
  text-align: center;
}

member-profile section.profile .friend-count {
  color: grey;
  font-size: 14px;
  margin-left: 2px;
  letter-spacing: -.5px;
  font-weight: 600;
  margin-bottom: 6px;
}

member-profile section.profile .friend-count span {
  font-weight: 300;
}

member-profile section.profile .button {
  background-color: #930807;
  color: white;
  padding: 3px 5px;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.75);
  cursor: pointer;
  transition: background-color .3s;
}

member-profile section.profile .button:hover {
  background-color: #7b0706;
}

member-profile section.profile hr {
  width: 80%;
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: .5px solid #d2d2d2;
  border-top: .5px solid #d2d2d2;
}

member-profile section.profile ul.member-navigation li, member-profile section.profile ul.member-navigation li, member-profile section.profile ul.member-help li {
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: color .3s;
}

member-profile section.profile ul.member-navigation li:last-child, member-profile section.profile ul.member-navigation li:last-child, member-profile section.profile ul.member-help li:last-child {
  margin-bottom: 0;
}

member-profile section.profile ul.member-navigation li.selected, member-profile section.profile ul.member-navigation li.selected, member-profile section.profile ul.member-help li.selected {
  font-weight: 700;
}

member-profile section.profile ul.member-navigation li:hover, member-profile section.profile ul.member-navigation li:hover, member-profile section.profile ul.member-help li:hover {
  color: #930807;
}

member-profile section.profile ul.member-help {
  position: absolute;
  bottom: 50px;
  left: 25px;
}

member-profile section.member-experience {
  min-width: calc(100% - 150px);
  min-height: calc(100% - 50px);
}

member-onboarding {
  background-color: #dadada;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
}

member-onboarding section.onboarding1.is-submitted {
  display: none;
}

member-onboarding section.onboarding2 {
  display: none;
}

member-onboarding section.onboarding2.is-ready {
  display: flex;
}

member-onboarding section.onboarding2.is-submitted {
  display: none;
}

member-onboarding h1 {
  font-weight: 900;
  font-size: 36px;
  margin-bottom: 24px;
}

member-onboarding form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
}

member-onboarding form .name {
  display: flex;
  justify-content: space-between;
}

member-onboarding form .name .first-name {
  width: 48%;
}

member-onboarding form .name .last-name {
  width: 48%;
}

member-onboarding form .date {
  display: flex;
  justify-content: space-between;
}

member-onboarding form .date input {
  width: 31%;
  margin-bottom: 18px;
}

member-onboarding form .country {
  display: flex;
  flex-direction: column;
}

member-onboarding form .datesex {
  display: flex;
  justify-content: space-between;
}

member-onboarding form .datesex .dob {
  width: 48%;
}

member-onboarding form .datesex .gender {
  width: 48%;
}

member-onboarding label {
  color: #848484;
  font-weight: 400;
  font-size: 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
}

member-onboarding input {
  border: 0;
  background-color: #FFF;
  box-shadow: none;
  font-size: 14px;
  line-height: 22px;
  padding: 10px;
  width: 100%;
  border: 0;
  color: #7f7f7f;
  font-size: 14px;
  height: auto;
  border-radius: 2px;
  -webkit-appearance: none;
  margin-bottom: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

member-onboarding input.is-error {
  background-color: #F6DCDD;
}

member-onboarding select {
  background-color: #fff;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  height: auto;
  border: 0;
  border-radius: 2px;
  background-position: 95% center;
  background-repeat: no-repeat;
  color: #7f7f7f;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appeareance: none;
  margin-bottom: 12px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAYAAAD+Bd/7AAAAU0lEQVR42jXMUQnAMAxF0Zjo19xUwEzUxKREy6AOJqBWBoX0BW4+DoSbEHP3Jq9cYol5SjOGkJUBizbzoMtP+BC0Xi9v2RLYNMtlGRIYNA4ED6wcTfpq/NY0RmUAAAAASUVORK5CYII=);
}

member-onboarding select.is-error {
  background-color: #F6DCDD;
}

member-onboarding .button {
  color: white;
  background-color: #920807;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 12px;
  border-radius: 3px;
  transition: background-color .3s;
  cursor: pointer;
}

member-onboarding .button:hover {
  background-color: #610505;
}

main-home {
  background-color: #1a1b1f;
  background-image: radial-gradient(circle at top right, #444751, #232428 50%);
  display: block;
  min-height: 100vh;
  padding-left: 150px;
}

main-home section.hero {
  background-image: url(/assets/images/factory.svg);
  background-size: 50%;
  background-position: right center;
  background-repeat: no-repeat;
  max-width: 1400px;
  margin: auto;
  padding-left: 5%;
  padding-right: 5%;
  opacity: 0;
  min-height: 350px;
}

main-home section.hero .cta {
  display: flex;
  height: 40vh;
  max-height: 600px;
}

main-home section.hero .cta .text {
  display: flex;
  flex-direction: column;
  width: 75%;
  justify-content: center;
}

main-home section.hero .cta .text h1 {
  background-color: black;
  color: #7c919e;
  align-self: flex-start;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  opacity: 0;
}

main-home section.hero .cta .text h2 {
  font-size: 64px;
  font-weight: 900;
  color: white;
  margin-bottom: 25px;
  opacity: 0;
}

main-home section.hero .cta .text p {
  font-size: 18px;
  line-height: 1.4;
  color: #7c919e;
  max-width: 60%;
  margin-bottom: 25px;
  opacity: 0;
}

main-home section.hero .cta .text .buttons {
  display: flex;
  opacity: 0;
}

main-home section.hero .cta .text .buttons .button {
  background-color: #930807;
  text-align: center;
  min-width: 100px;
  color: white;
  padding: 10px 20px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 300;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.75);
  cursor: pointer;
  transition: background-color .3s, color .3s;
  margin-right: 25px;
}

main-home section.hero .cta .text .buttons .button:hover {
  background-color: #620505;
}

main-home section.hero .cta .text .buttons .button.ghost {
  background-color: transparent;
  border: solid #7c919e 1px;
  color: #7c919e;
}

main-home section.hero .cta .text .buttons .button.ghost:hover {
  background-color: #627885;
  color: white;
}

@media screen and (max-width: 1800px) {
  main-home section.hero {
    padding-left: 3%;
    padding-right: 3%;
  }
}

main-home section.recommended {
  margin: auto;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 50px;
}

main-home section.recommended h3 {
  font-size: 18px;
  font-weight: 900;
  color: white;
  margin-bottom: 10px;
  display: inline-block;
  margin-right: 25px;
  opacity: 0;
}

main-home section.recommended .factories {
  display: flex;
  justify-content: space-between;
}

main-home section.recommended .factory {
  width: 20%;
  height: 240px;
  background-size: cover;
  background-color: #121317;
  padding: 15px;
  border-radius: 4px;
  margin-left: 0px;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
  opacity: 0;
}

main-home section.recommended .factory .tag {
  background-color: black;
  color: #7c919e;
  align-self: flex-start;
  padding: 5px;
  font-size: 12px;
  margin-bottom: 10px;
  display: inline;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

main-home section.recommended .factory .genre {
  color: #7c919e;
  font-size: 11px;
  margin-bottom: 5px;
}

main-home section.recommended .factory .factory-name {
  color: white;
  font-weight: 700;
  margin-bottom: 5px;
}

main-home section.recommended .factory .factory-blurb {
  color: #7c919e;
  font-size: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.2;
}

main-home section.recommended .factory .info {
  margin-top: 135px;
  display: flex;
}

main-home section.recommended .factory .stats {
  display: flex;
  flex-direction: column;
  align-items: center;
}

main-home section.recommended .factory .stats img.logo {
  width: 40px;
  height: 40px;
  background-size: cover;
  border-radius: 50%;
  box-shadow: 0 0 0 2pt white;
  margin-bottom: 5px;
}

main-home section.recommended .factory .stats .factory-followers {
  font-size: 12px;
  color: white;
  font-weight: 400;
  text-align: right;
  margin-top: 8px;
  letter-spacing: 1px;
  background-position: center;
}

main-home section.recommended .factory .text {
  width: calc(100% - 40px);
  padding-left: 15px;
  max-height: 68px;
  overflow: hidden;
}

main-home section.recommended .factory:first-child {
  margin-left: 0px;
}

main-home section.recommended .factory:last-child {
  margin-right: 0px;
}

main-home section.recommended .factory:hover {
  box-shadow: 6px 6px 13px 0px rgba(0, 0, 0, 0.75);
  background-size: auto 103%;
  transition: box-shadow .3s, background-size 1s;
}

@media screen and (max-width: 1800px) {
  main-home section.recommended {
    margin-left: 3%;
    margin-right: 3%;
  }
}

main-home section.category-select {
  display: flex;
  margin: auto;
  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 50px;
  opacity: 0;
  flex-wrap: wrap;
}

main-home section.category-select .select {
  background-color: black;
  color: white;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color .3s;
}

main-home section.category-select .select:hover {
  background-color: #1a1a1a;
}

@media screen and (max-width: 1800px) {
  main-home section.category-select {
    margin-left: 3%;
    margin-right: 3%;
  }
}

main-home section.category {
  margin: auto;
  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 50px;
}

main-home section.category .category-head {
  display: flex;
}

main-home section.category .category-head select {
  font-size: 12px;
  line-height: 1;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"), linear-gradient(to bottom, #000 0%, #000 100%);
  background-color: #000;
  color: #aaa;
  border: none;
  top: -3px;
  font-weight: 300;
  padding: 0.4em 1.8em .4em .8em;
  opacity: 0;
}

main-home section.category .category-head select:focus {
  border-color: #aaa;
  box-shadow: none;
  box-shadow: 0 0 0 3px -moz-mac-focusring;
}

main-home section.category h3 {
  font-size: 18px;
  font-weight: 900;
  color: white;
  margin-bottom: 10px;
  display: inline-block;
  margin-right: 15px;
  opacity: 0;
}

main-home section.category .factories {
  display: flex;
  justify-content: space-between;
}

main-home section.category .factory {
  width: 13%;
  height: 240px;
  background-size: cover;
  background-color: #121317;
  padding: 15px;
  border-radius: 4px;
  background-size: cover;
  background-position: center;
  margin-left: 10px;
  margin-right: 10px;
  opacity: 0;
}

main-home section.category .factory .tag {
  background-color: black;
  color: #7c919e;
  align-self: flex-start;
  padding: 5px;
  font-size: 12px;
  margin-bottom: 10px;
  display: inline;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

main-home section.category .factory .genre {
  color: #7c919e;
  font-size: 11px;
  margin-bottom: 5px;
}

main-home section.category .factory .factory-name {
  color: white;
  font-weight: 700;
  margin-bottom: 5px;
}

main-home section.category .factory .factory-blurb {
  color: #7c919e;
  font-size: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.2;
}

main-home section.category .factory .info {
  margin-top: 135px;
  display: flex;
}

main-home section.category .factory .stats {
  display: flex;
  flex-direction: column;
  align-items: center;
}

main-home section.category .factory .stats img.logo {
  width: 40px;
  height: 40px;
  background-size: cover;
  border-radius: 50%;
  box-shadow: 0 0 0 2pt white;
  margin-bottom: 5px;
  background-position: center;
}

main-home section.category .factory .stats .factory-followers {
  font-size: 12px;
  color: white;
  font-weight: 400;
  text-align: right;
  margin-top: 8px;
  letter-spacing: 1px;
}

main-home section.category .factory .text {
  width: calc(100% - 40px);
  padding-left: 15px;
  overflow: hidden;
}

main-home section.category .factory:first-child {
  margin-left: 0px;
}

main-home section.category .factory:last-child {
  margin-right: 0px;
}

@media screen and (max-width: 1800px) {
  main-home section.category {
    margin-left: 3%;
    margin-right: 3%;
  }
}

main-home.is-ready section.hero {
  opacity: 1;
  transition: opacity 1s;
}

main-home.is-ready section.hero .cta .text h2 {
  opacity: 1;
  transition: opacity 1s .4s;
}

main-home.is-ready section.hero .cta .text h1 {
  opacity: 1;
  transition: opacity 1s .3s;
}

main-home.is-ready section.hero .cta .text p {
  opacity: 1;
  transition: opacity 1s .5s;
}

main-home.is-ready section.hero .cta .text .buttons {
  opacity: 1;
  transition: opacity 1s .6s;
}

main-home.is-ready section.category .factory, main-home.is-ready section.recommended .factory {
  opacity: 1;
}

main-home.is-ready section.category .factory:nth-child(1), main-home.is-ready section.recommended .factory:nth-child(1) {
  transition: box-shadow .3s, background-size .3s, opacity .5s;
}

main-home.is-ready section.category .factory:nth-child(2), main-home.is-ready section.recommended .factory:nth-child(2) {
  transition: box-shadow .3s, background-size .3s, opacity .5s .1s;
}

main-home.is-ready section.category .factory:nth-child(3), main-home.is-ready section.recommended .factory:nth-child(3) {
  transition: box-shadow .3s, background-size .3s, opacity .5s .2s;
}

main-home.is-ready section.category .factory:nth-child(4), main-home.is-ready section.recommended .factory:nth-child(4) {
  transition: box-shadow .3s, background-size .3s, opacity .5s .3s;
}

main-home.is-ready section.category .factory:nth-child(5), main-home.is-ready section.recommended .factory:nth-child(5) {
  transition: box-shadow .3s, background-size .3s, opacity .5s .4s;
}

main-home.is-ready section.category .factory:nth-child(6), main-home.is-ready section.recommended .factory:nth-child(6) {
  transition: box-shadow .3s, background-size .3s, opacity .5s .5s;
}

main-home.is-ready section.category .factory:nth-child(7), main-home.is-ready section.recommended .factory:nth-child(7) {
  transition: box-shadow .3s, background-size .3s, opacity .5s .6s;
}

main-home.is-ready section.category .factory:nth-child(18), main-home.is-ready section.recommended .factory:nth-child(18) {
  transition: box-shadow .3s, background-size .3s, opacity .5s .7s;
}

main-home.is-ready section.category h3, main-home.is-ready section.recommended h3, main-home.is-ready section.category select, main-home.is-ready section.recommended select {
  opacity: 1;
  transition: opacity .5s;
}

main-home.is-ready section.category-select {
  opacity: 1;
  transition: opacity .5s;
}

@media screen and (max-width: 1400px) {
  main-home section.category .factory {
    width: 16%;
  }
  main-home section.category .factory:nth-child(7) {
    display: none;
  }
}

@media screen and (max-width: 1280px) {
  main-home section.hero .cta .text h2 {
    font-size: 50px;
    margin-bottom: 8px;
  }
  main-home section.hero .cta .text p {
    font-size: 16px;
  }
  main-home section.recommended .factory {
    width: 25%;
  }
  main-home section.recommended .factory:nth-child(5) {
    display: none;
  }
  main-home section.category .factory {
    width: 20%;
    padding: 10px;
  }
  main-home section.category .factory .text {
    padding-left: 10px;
    width: calc(100% - 20px);
  }
  main-home section.category .factory .stats img.logo {
    width: 30px;
    height: 30px;
  }
  main-home section.category .factory:nth-child(6) {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  main-home section.hero .cta .text h2 {
    font-size: 40px;
  }
  main-home section.recommended .factory {
    margin-right: 0px;
    width: 33%;
    padding: 5px;
  }
  main-home section.recommended .factory .text {
    padding-right: 5px;
  }
  main-home section.recommended .factory .stats img.logo {
    width: 30px;
    height: 30px;
  }
  main-home section.recommended .factory:nth-child(4) {
    display: none;
  }
  main-home section.category-select .select {
    font-size: 14px;
  }
  main-home section.category .factory {
    margin-left: 5px;
    margin-right: 5px;
  }
  main-home section.category .factory .factory-blurb {
    font-size: 11px;
  }
  main-home section.category .factory .info {
    margin-top: 125px;
  }
  main-home section.category .factory:nth-child(6) {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  main-home section.category .factory {
    width: 25%;
  }
  main-home section.category .factory:nth-child(5) {
    display: none;
  }
}

@media screen and (max-width: 720px) {
  main-home {
    padding-left: 0px;
  }
  main-home section.recommended .factories {
    max-width: 100vw;
    overflow: scroll;
  }
  main-home section.recommended .factory {
    min-width: 200px;
    padding: 15px;
  }
  main-home section.recommended .factory .text {
    padding-right: 15px;
  }
  main-home section.recommended .factory .stats img.logo {
    width: 40px;
    height: 40px;
  }
  main-home section.recommended .factory:nth-child(4), main-home section.recommended .factory:nth-child(5) {
    display: block;
  }
  main-home section.category .factories {
    max-width: 100vw;
    overflow: scroll;
  }
  main-home section.category .factory {
    min-width: 160px;
  }
  main-home section.category .factory:nth-child(5), main-home section.category .factory:nth-child(6), main-home section.category .factory:nth-child(7) {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  main-home section.hero .cta .text {
    width: 100%;
  }
  main-home section.hero .cta .text .buttons {
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  main-home section.hero {
    background: transparent;
    margin-bottom: 50px;
  }
  main-home section.hero .cta .text p {
    max-width: 100%;
  }
  main-home section.hero .cta .text h2 {
    font-size: 30px;
  }
}

main-about {
  background-color: #1a1b1f;
  background-image: radial-gradient(circle at top right, #444751, #232428 50%);
  display: block;
  min-height: 100vh;
  padding-left: 150px;
}

main-about section.hero {
  color: white;
  text-align: center;
  padding-top: 100px;
  padding-right: 5%;
  padding-left: 5%;
}

main-about section.hero h2 {
  font-size: 40px;
  color: white;
  margin-bottom: 10px;
  text-shadow: 4px 4px 8px black;
}

main-about section.hero h2 strong {
  color: #920807;
  font-weight: 900;
}

main-about section.hero h1 {
  font-size: 90px;
  font-weight: 900;
  color: white;
  margin-bottom: 25px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: .8;
  text-shadow: 4px 4px 8px black;
}

main-about section.hero .divide {
  display: flex;
}

main-about section.hero .divide .left {
  width: 60%;
}

main-about section.hero .divide .left p {
  text-align: right;
  margin-left: auto;
  margin-right: 50px;
}

main-about section.hero .divide .left p strong {
  color: #920807;
  font-weight: 900;
  text-shadow: 2px 2px 4px black;
}

main-about section.hero .divide .left .buttons {
  display: flex;
  margin-right: 25px;
  justify-content: flex-end;
  margin-top: 35px;
}

main-about section.hero .divide .left .buttons .button {
  background-color: #930807;
  text-align: center;
  min-width: 100px;
  color: white;
  padding: 10px 20px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 300;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.75);
  cursor: pointer;
  transition: background-color .3s, color .3s;
  margin-right: 25px;
}

main-about section.hero .divide .left .buttons .button:hover {
  background-color: #620505;
}

main-about section.hero .divide .left .buttons .button.ghost {
  background-color: transparent;
  border: solid #7c919e 1px;
  color: #7c919e;
}

main-about section.hero .divide .left .buttons .button.ghost:hover {
  background-color: #627885;
  color: white;
}

main-about section.hero .divide .right img {
  height: 500px;
  position: relative;
  top: -90px;
}

main-about section.hero p {
  font-size: 18px;
  line-height: 1.4;
  color: #7c919e;
  max-width: 60%;
  margin-bottom: 25px;
  text-align: center;
  margin: auto;
}

main-about section.explainer {
  padding-right: 5%;
  padding-left: 5%;
  padding-bottom: 100px;
}

main-about section.explainer h3 {
  font-size: 35px;
  font-weight: 900;
  color: white;
  margin-bottom: 25px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1;
  text-shadow: 4px 4px 8px black;
}

main-about section.explainer p {
  font-size: 18px;
  line-height: 1.4;
  color: #7c919e;
  margin-bottom: 25px;
  margin: auto;
  max-width: 1200px;
}

main-about section.explainer h4 {
  font-size: 27px;
  font-weight: 900;
  color: white;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1;
  text-shadow: 4px 4px 8px black;
}

main-about section.explainer .features {
  max-width: 1200px;
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;
  margin: auto;
  margin-top: 50px;
}

main-about section.explainer .features .feature {
  display: flex;
  margin-bottom: 25px;
  border-bottom: 1px solid #465056;
  padding-bottom: 25px;
}

main-about section.explainer .features .feature .left {
  text-align: right;
}

main-about section.explainer .features .feature .left img {
  max-height: 100px;
  margin-right: 35px;
  max-width: 100px;
}

main-about section.explainer .features .feature .right {
  text-align: left;
}

main-about section.explainer .features .feature:last-child {
  border: none;
}

@media screen and (max-width: 1024px) {
  main-about section.hero h1 {
    font-size: 70px;
  }
  main-about section.hero h2 {
    font-size: 31px;
  }
  main-about section.hero p {
    max-width: initial;
  }
  main-about section.hero .divide .right img {
    height: 350px;
    top: -50px;
  }
}

@media screen and (max-width: 800px) {
  main-about section.hero h1 {
    font-size: 50px;
  }
}

@media screen and (max-width: 720px) {
  main-about {
    padding: 5%;
  }
  main-about section.hero {
    padding: 0px;
  }
  main-about section.hero .divide .right img {
    height: 273px;
    top: -41px;
  }
  main-about section.hero .divide .left {
    width: 80%;
  }
  main-about section.explainer {
    padding: 0px;
  }
  main-about section.explainer h4 {
    margin-bottom: 25px;
  }
  main-about section.explainer .features {
    padding: 0px;
  }
}

@media screen and (max-width: 600px) {
  main-about section.hero h1 {
    font-size: 42px;
    margin-bottom: 35px;
  }
  main-about section.hero h2 {
    font-size: 27px;
    margin-bottom: 35px;
  }
  main-about section.hero p {
    font-size: 16px;
  }
  main-about section.explainer .features .feature {
    flex-direction: column;
  }
  main-about section.explainer .features .feature .left {
    text-align: center;
    margin-bottom: 15px;
  }
  main-about section.explainer p {
    font-size: 16px;
  }
}

@media screen and (max-width: 500px) {
  main-about section.hero {
    margin-bottom: 75px;
  }
  main-about section.hero .divide .left .buttons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  main-about section.hero .divide .left .buttons .button {
    width: 160px;
    margin-bottom: 15px;
  }
  main-about section.hero .divide .left p {
    text-align: left;
  }
}

main-help {
  display: block;
  min-height: 100vh;
  padding-left: 150px;
}

main-terms {
  display: block;
  min-height: 100vh;
  padding-left: 150px;
}

main-contact {
  display: block;
  min-height: 100vh;
  padding-left: 150px;
}

/*-----------------------------------*\
		THEME
\*-----------------------------------*/
/*-----------------------------------------------------*\

	*Filename:		default.scss
	*Description: 	contains the baselines specifically for 
					this project
	*Version:		1.0.0(2015-05-29)
	*Website:		http://schonne.com
	*Author:		Schonne Eldridge

 ===TABLE OF CONTENTS:===================================

	$_FONTS					dynamic fonts
	$_PROJECT BASELINE		baselines for the theme

\*------------------------------------------------------*/
