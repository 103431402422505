factory-member-profile {
	display: flex;

	section.left-panel {
		transform: translate(0%, -100%);
		transition: transform .5s .3s;

		.profile-banner {
			height: 450px;
			background-size: cover;
			background-position: center;
			position: relative;
			width: 250px;
			box-shadow: inset 0 0 100px black;
			opacity: 0;
			transition: opacity 1s .6s;
		}
	
		.profile-facts {
			padding: 0px;
			padding-top: 15px;
			padding: 15px;
			padding-left: 12px;
			margin-bottom: 25px;
	
			.profile-name {
				font-size: 21px;
				text-align: center;
				font-weight: 900;
				opacity: 0;
				transition: opacity 1s .8s;
			}
			.profile-location {
				font-size: 13px;
				color: #bbbbbb;
				text-transform: uppercase;
				font-weight: 300;
				text-align: center;
				opacity: 0;
				transition: opacity 1s .9s;
			}

			.levels {
				display: flex;
				margin-top: 14px;
				border-top: 1px solid #6d6666;
				padding: 5px;
				border-radius: 4px;
				max-width: 0px;
				transition: max-width 1s 1s;
				overflow: hidden;

				&>div {
					width: 50%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					border-right: 1px solid #6d6666;
					overflow: hidden;
					opacity: 0;
					transition: opacity 1s 1.5s;
					
					.score {
						font-size: 24px;
						font-weight: 700;
						text-align: center;
					}

					.attribute {
						font-size: 12px;
						text-transform: uppercase;
						font-weight: 300;
						color: #bbbbbb;
					}

					&:last-child {
						border-right: none;
						opacity: 0;
						transition: opacity 1s 1.6s;
					}
				}
			}

			.progress-bar {
				width: 100%;
				height: 15px;
				border-radius: 16px;
				box-shadow: inset 2px 2px 5px 1px #00000080;
				background-color: transparent;
				margin-top: 5px;
				max-width: 0px;
				overflow: hidden;
				transition: max-width 1s .8s;

				.progress {
					width: 20%;
					background-color: white;
					height: 15px;
					border-bottom-left-radius: 16px;
					border-top-left-radius: 16px;
					box-shadow: inset 2px 2px 5px 1px #00000080;
				}
			}

			.points {
				display: flex;
				justify-content: space-between;
				margin-top: 5px;
				opacity: 0;
				transition: opacity 1s 1.25s;
				margin-bottom: 15px;

				&>div {
					font-size: 12px;
					color: #bbbbbb;

					&:first-child {
						margin-left: 5px;
					}
				}
			}

			.profile-mastermind {
				margin-left: 12px;
				margin-bottom: 15px;
				margin-top: 5px;
				opacity: 0;
				transition: opacity 1s 1s;
	
				.mastermind-title {
					font-weight: 600;
					font-size: 10px;
					text-align: left;
					text-transform: uppercase;
					transition: opacity .5s 1s;
					color: #bbbbbb;
				}
	
				.mastermind-link {
					font-size: 15px;
					cursor: pointer;
					transition: color .3s;
	
					&:hover {
						color: #bbbbbb;
					}
				}
	
				.mastermind-count {
					font-size: 14px;
					color: #bbbbbb;
				}
			}
	
			.profile-dates {
				margin-left: 12px;
				margin-bottom: 0px;
				margin-top: 5px;
				opacity: 0;
				transition: opacity 1s 1.25s;
				margin-bottom: 25px;
	
				.profile-date {
					margin-bottom: 15px;
	
					.tag {
						font-weight: 600;
						font-size: 10px;
						text-align: left;
						text-transform: uppercase;
						transition: opacity .5s 1s;
						color: #bbbbbb;
					}
	
					.date {
						font-size: 15px;
					}
				}
			}
	
			.profile-stats {
				padding-left: 12px;
				border-top: 1px solid #6d6666;
				padding-top: 25px;
				margin-top: 0px;
				max-width: 0px;
				overflow: hidden;
				transition: max-width 1s 1s;
	
				.stat {
					display: flex;
					margin-bottom: 10px;
					opacity: 0;
					transition: opacity 1s 1s;
	
					.score {
						margin-right: 10px;
						font-size: 20px;
						font-weight: 300;
					}
	
					.description {
						font-size: 14px;
						color: #bbbbbb;
					}
				}
			}
		}


	}

	section.right-panel {
		background-color: #f4f4f4;

		.main-layout {
			width: 100%;
			padding-top: 0px;
			padding-left: 0px;
			padding-bottom: 2%;
			display: flex;
			flex-direction: column;

			.current-status {
				background-color: #121317;
				width: 98%;
				padding: 2%;
				padding-bottom: 1%;
				margin-bottom: 2%;
				border-bottom-right-radius: 4px;
				transform: translate(0%, -100%);
				transition: transform .5s .3s;
				min-height: 200px;
			}
		}

		.side-panel {
			padding-top: 60px;
			width: 250px;
			min-width: 250px;
			max-width: 250px;

			h2 {
				font-weight: 600;
				font-size: 10px;
				margin-bottom: 10px;
				margin-top: 25px;
				text-align: left;
				text-transform: uppercase;
				transition: opacity .5s .3s;
				opacity: 0;
			}

			.achievements {
				display: flex;
				flex-direction: column;
				padding-right: 15px;

				.achievement {
					display: flex;
					align-items: self-end;
					max-height: 45px;
					min-height: 0px;
					overflow: hidden;
					border: 1px solid #bbbbbb;
					border-radius: 4px;
					padding: 3px;
					transition: border .3s;
					cursor: pointer;
					transition: max-height .5s, box-shadow .5s, opacity .8s, transform .8s, background-color .3s;
					margin-bottom: 10px;
					opacity: 0;
					transform: translate(-50px, 0px);

					.achievement-images {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;

						.achievement-icon {
							min-width: 25px;
							min-height: 25px;
							display: inline-block;
							margin-right: 10px;
						}

						.achievement-progress {
							font-size: 14px;
						}
					}

					.achievement-description {
						text-align: left;

						.achievement-title {
							font-weight: 700;
							font-size: 14px;
							text-align: left;
							cursor: pointer;
							transition: color .3s;
							margin-bottom: 3px;
							transition: color .3s;
						}

						.achievement-details {
							font-size: 12px;
							color: #7d7d7d;
						}
					}

					.achievement-rewards {
						display: flex;
						margin-top: 5px;
						justify-content: space-between;
						border-top: 1px solid #cacaca;
						border-bottom: 1px solid #cacaca;
						padding-top: 5px;
						padding-bottom: 5px;

						.points {
							font-size: 16px;

							img {
								width: 16px;
								height: 16px;
								margin-left: 5px;
								position: relative;
								top: 2px;
							}
						}

						.reputation {
							font-size: 16px;

							img {
								width: 16px;
								height: 16px;
								margin-left: 5px;
								position: relative;
								top: 2px;
							}
						}
					}

					.achievement-stats {
						padding-top: 5px;
						padding-bottom: 5px;

						.stat {
							font-size: 12px;
							font-weight: 300;
							color: #7d7d7d;
						}
					}

					&:hover {
						border: 1px solid #930807;
						box-shadow: 2px 3px 7px 0px rgba(0,0,0,0.40);
						background-color: white;

						.achievement-description .achievement-title {
							color: #930807;
						}
					}

					&.is-showing {
						max-height: 500px;
						border: 1px solid #930807;
						box-shadow: 2px 3px 7px 0px rgba(0,0,0,0.40);
						background-color: white;
						.achievement-description .achievement-title {
							color: #930807;
						}
					}
				}
			}
		}
	}

	&.is-ready {

		section.left-panel {
			transform: translateY(0%);
	
			.profile-banner {
				opacity: 1;
			}

			.profile-facts {
		
				.profile-name {
					opacity: 1;
				}

				.profile-location {
					opacity: 1;
				}

				.levels {
					max-width: 250px;

					&>div  {
						opacity: 1;
					}
				}

				.progress-bar {
					max-width: 250px;
				}

				.points {
					opacity: 1;
				}

				.profile-mastermind {
					opacity: 1;
				}
	
				.profile-dates {
					opacity: 1;
				}
	
				.profile-stats {
					max-width: 250px;
	
					.stat {
						opacity: 1;
						transition: opacity .8s 1.25s;
					
						&:nth-child(1) {
							transition: opacity .8s 1.25s;
						}
						&:nth-child(2) {
							transition: opacity .8s 1.35s;
						}
						&:nth-child(3) {
							transition: opacity .8s 1.45s;
						}
						&:nth-child(4) {
							transition: opacity .8s 1.55s;
						}
						&:nth-child(4) {
							transition: opacity .8s 1.65s;
						}
					}
				}
			}
		}

		section.right-panel {

			.main-layout {

				.current-status {
					transform: translate(0px, 0px);
				}
			}

			.side-panel {

				h2 {
					opacity: 1;
				}

				.achievements {

					.achievement {
						transform: translate(0px, 0px);
						opacity: 1;

						&:nth-child(1) {
							transition: max-height .5s, box-shadow .5s, opacity .8s .5s, transform .5s .5s;
						}
						&:nth-child(2) {
							transition: max-height .5s, box-shadow .5s, opacity .8s .6s, transform .5s .6s;
						}
						&:nth-child(3) {
							transition: max-height .5s, box-shadow .5s, opacity .8s .7s, transform .5s .7s;
						}
						&:nth-child(4) {
							transition: max-height .5s, box-shadow .5s, opacity .8s .8s, transform .5s .8s;
						}
						&:nth-child(5) {
							transition: max-height .5s, box-shadow .5s, opacity .8s .9s, transform .5s .9s;
						}
						&:nth-child(6) {
							transition: max-height .5s, box-shadow .5s, opacity .8s 1s, transform .5s 1s;
						}
						&:nth-child(7) {
							transition: max-height .5s, box-shadow .5s, opacity .8s 1.1s, transform .5s 1.1s;
						}
						&:nth-child(8) {
							transition: max-height .5s, box-shadow .5s, opacity .8s 1.2s, transform .5s 1.2s;
						}
						&:nth-child(9) {
							transition: max-height .5s, box-shadow .5s, opacity .8s 1.3s, transform .5s 1.3s;
						}
						&:nth-child(10) {
							transition: max-height .5s, box-shadow .5s, opacity .8s 1.4s, transform .5s 1.4s;
						}
						&:nth-child(11) {
							transition: max-height .5s, box-shadow .5s, opacity .8s 1.5s, transform .5s 1.5s;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 1024px) {
		flex-direction: column;

		section.left-panel {
			width: calc(100vw - 150px);
			min-height: 450px;
			max-height: 450px;
			flex-direction: initial;
			align-items: stretch;

			.profile-banner {
				height: auto;
			}

			.profile-facts {
				padding: 25px;
				margin-top: 50px;
				width: calc(100% - 250px);

				.levels {
					margin-left: auto;
					margin-right: auto;
					margin-top: 5px;

					&> div .score {
						font-size: 21px;
					}
				}

				.progress-bar {
					margin-left: auto;
					margin-right: auto;
				}

				.points {
					max-width: 250px;
					margin-left: auto;
					margin-right: auto;
				}

				.profile-details {
					display: flex;

					.profile-attributes {
						width: 50%;
						text-align: right;
						padding-right: 15px;

						.mastermind-title, .tag {
							text-align: right;
						}

						.profile-mastermind, .profile-dates .profile-date {
							margin-bottom: 25px;

							&:last-child {
								margin-bottom: 0px;
							}
						}
					}

					.profile-stats {
						width: 50%;
						border-top: none;
						padding-top: 0px;
						border-left: 1px solid #6d6666;
						padding-left: 15px;
						max-height: 0px;
						transition: max-height .8s 1s;
						overflow: hidden;

						.stat {
							margin-bottom: 10px;

							&:last-child {
								margin-bottom: 0px;
							}
						}
					}
				}
			}

			.profile-banner {
				min-width: 250px;
			}
		}

		section.right-panel {
			width: calc(100vw - 150px);

			.main-layout {

				.current-status{
					transform: translate(-100%, 0%);

					.status-container {
						flex-direction: column;

						.status-update {
							width: 100%;
							margin-bottom: 10px;
						}

						.promotions {
							width: 100%;
						}
					}
				}
			}

			.side-panel {
				padding-top: 0px;
				margin-top: 0px;
				min-width: 200px;
				max-width: 200px;
			}
		}

		&.is-ready section.right-panel .main-layout .current-status {
			transform: translate(0%, 0%);
		}

		&.is-ready section.left-panel .profile-facts .profile-details .profile-stats {
			max-height: 300px;
		}
	}

	@media screen and (max-width: 720px) {


		section.left-panel {
			width: 100vw;
			min-height: initial;


			.profile-banner {
				width: 100vw;
				height: 200px;
				position: absolute;
				background-position: center 25%;
			}

			.profile-facts {
				padding: 3%;
				background: linear-gradient(0deg, #930807 0%, #930807 34%,#121317 83%, rgba(18, 19, 23, 0) 100%);
				margin-top: 133px;
				width: 100%;

				.profile-name {
					font-size: 32px;
				}

				ul.social {
					margin-bottom: 0px;
				}
			}
		}

		section.right-panel {
			width: 100vw;
			flex-direction: column;

			.main-layout .current-status {
				width: 100%;
				border-radius: 0px;
			}
			
			.side-panel {
				width: 100%;
				min-width: 100%;
				max-width: 100%;
				padding-left: 5%;
				padding-right: 5%;
				min-height: auto;

				h2 {
					margin-top: 0px;
				}

				.achievements .achievement {
					margin-bottom: 20px;
					max-height: 50px;
				}
			}
		}
	}

	@media screen and (max-width: 600px) {

	}

}