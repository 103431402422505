/*-----------------------------------------------------*\

	*Filename:		default.scss
	*Description: 	contains the baselines specifically for 
					this project
	*Version:		1.0.0(2015-05-29)
	*Website:		http://schonne.com
	*Author:		Schonne Eldridge

 ===TABLE OF CONTENTS:===================================

	$_FONTS					dynamic fonts
	$_PROJECT BASELINE		baselines for the theme

\*------------------------------------------------------*/

