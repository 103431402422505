/*-----------------------------------------------------*\

	*Filename:		base.scss
	*Description: 	contains the baselines specifically
					for the project
	*Version:		1.0.0(2015-03-10)
	*Website:		http://schonne.com
	*Author:		Schonne Eldridge

 ===TABLE OF CONTENTS:===================================

	$_FONTS					dynamic fonts
	$_PROJECT BASELINE		baselines for the project suc

\*------------------------------------------------------*/



/*-----------------------------------*\
		$_FONTS
\*-----------------------------------*/



/*-----------------------------------*\
		$_PROJECT BASELINE
\*-----------------------------------*/

//body
body, html, main {
	height: 100%;
	font-family: 'Montserrat', sans-serif;
	background-color: #fff;
	height: auto;
	overflow-x: hidden;
}

main {
	width: 100vw;
}

section.left-panel {
	width: 250px;
	display: flex;
	flex-direction: column;
	background-color: #930807;
	color: white;
	min-height: 100vh;
}

ul.social {
	display: flex;
	align-items: center;
	justify-content: center;

	li {
		margin-right: 8px;
		margin-left: 8px;

		a {
			transition: opacity .3s;

			&:hover {
				opacity: .6;
			}
		}
	}
}

section.right-panel {
	width: calc(100vw - 400px);
	display: flex;

	.main-layout {
		display: flex;
		padding-top: 50px;
		padding-left: 50px;
		padding-bottom: 100px;
	}

	.side-panel {
		min-width: 250px;
		margin-right: 50px;
		text-align: center;
		min-height: 100vh;
		margin-left: auto;
		margin-right: 0px;
	}
}

select {
	display: block;
	font-size: 16px;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	font-family: 'Montserrat', sans-serif;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	border-radius: .5em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}
select::-ms-expand {
	display: none;
}
select:hover {
	border-color: #888;
}
select:focus {
	border-color: #aaa;
	box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222;
	outline: none;
}
select option {
	font-weight:normal;
}

@media screen and (max-width: 720px) {
	main {
		margin-top: 50px;
		margin-bottom: 48px;
		min-height: 100vh;
	}
}