main-about {
	background-color: #1a1b1f;
	background-image: radial-gradient(circle at top right, #444751, #232428 50%);
	display: block;
	min-height: 100vh;
	padding-left: 150px;
	
	section.hero {
		color: white;
		text-align: center;
		padding-top: 100px;
		padding-right: 5%;
		padding-left: 5%;

		h2 {
			font-size: 40px;
			color: white;
			margin-bottom: 10px;
			text-shadow: 4px 4px 8px rgb(0, 0, 0);

			strong {
				color: #920807;
				font-weight: 900;
			}
		}

		h1 {
			font-size: 90px;
			font-weight: 900;
			color: white;
			margin-bottom: 25px;
			max-width: 800px;
			margin-left: auto;
			margin-right: auto;
			line-height: .8;
			text-shadow: 4px 4px 8px rgb(0, 0, 0);
		}

		.divide {
			display: flex;

			.left {
				width: 60%;
				

				p {
					text-align: right;
					margin-left: auto;
					margin-right: 50px;

					strong {
						color: #920807;
						font-weight: 900;
						text-shadow: 2px 2px 4px rgb(0, 0, 0);
					}
				}

				.buttons {
					display: flex;
					margin-right: 25px;
					justify-content: flex-end;
					margin-top: 35px;

					.button {
						background-color: #930807;
						text-align: center;
						min-width: 100px;
						color: white;
						padding: 10px 20px;
						border-radius: 3px;
						font-size: 12px;
						font-weight: 300;
						box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.75);
						cursor: pointer;
						transition: background-color .3s, color .3s;
						margin-right: 25px;

						&:hover {
							background-color: darken(#930807, 10%);
						}

						&.ghost {
							background-color: transparent;
							border: solid #7c919e 1px;
							color: #7c919e;

							&:hover {
								background-color: darken(#7c919e, 10%);
								color: white;
							}
						}
					}
				}
				
			}

			.right {

				img {
					height: 500px;
					position: relative;
					top: -90px;
				}
			}
		}

		p {
			font-size: 18px;
			line-height: 1.4;
			color: #7c919e;
			max-width: 60%;
			margin-bottom: 25px;
			text-align: center;
			margin: auto;
		}
	}

	section.explainer {
		padding-right: 5%;
		padding-left: 5%;
		padding-bottom: 100px;

		h3 {
			font-size: 35px;
			font-weight: 900;
			color: white;
			margin-bottom: 25px;
			max-width: 1200px;
			margin-left: auto;
			margin-right: auto;
			line-height: 1;
			text-shadow: 4px 4px 8px rgb(0, 0, 0);
		}

		p {
			font-size: 18px;
			line-height: 1.4;
			color: #7c919e;
			margin-bottom: 25px;
			margin: auto;
			max-width: 1200px;
		}

		h4 {
			font-size: 27px;
			font-weight: 900;
			color: white;
			max-width: 1200px;
			margin-left: auto;
			margin-right: auto;
			line-height: 1;
			text-shadow: 4px 4px 8px rgb(0, 0, 0);
		}

		.features {
			max-width: 1200px;
			text-align: center;
			padding-left: 50px;
			padding-right: 50px;
			margin: auto;
			margin-top: 50px;

			.feature {
				display: flex;
				margin-bottom: 25px;
				border-bottom: 1px solid #465056;
				padding-bottom: 25px;

				.left {
					text-align: right;

					img {
						max-height: 100px;
						margin-right: 35px;
						max-width: 100px;
					}
				}

				.right {
					text-align: left;
				}

				&:last-child {
					border: none;
				}
			}
		}
	}

	@media screen and (max-width: 1024px) {
		section.hero {

			h1 {
				font-size: 70px;
			}

			h2 {
				font-size: 31px;
			}

			p { 
				max-width: initial;
			}

			.divide .right img {
				height: 350px;
				top: -50px;
			}
		}
	}

	@media screen and (max-width: 800px) {
		section.hero {

			h1 {
				font-size: 50px;
			}
		}
	}

	@media screen and (max-width: 720px) {
		padding: 5%;

		section.hero {
			padding: 0px;

			.divide .right img {
				height: 273px;
				top: -41px;
			}

			.divide .left {
				width: 80%;
			}
		}

		section.explainer {
			padding: 0px;

			h4 {
				margin-bottom: 25px;
			}

			.features {
				padding: 0px;
			}
		}

	}

	@media screen and (max-width: 600px) {

		section.hero {
			h1 {
				font-size: 42px;
				margin-bottom: 35px;
			}
			h2 {
				font-size: 27px;
				margin-bottom: 35px;
			}
			p {
				font-size: 16px;
			}
		}

		section.explainer {

			.features .feature {
				flex-direction: column;

				.left {
					text-align: center;
					margin-bottom: 15px;
				}
			}

			p {
				font-size: 16px;
			}
		}
	}

	@media screen and (max-width: 500px) {

		section.hero {
			margin-bottom: 75px;
			.divide .left .buttons {
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.button {
					width: 160px;
					margin-bottom: 15px;
				}
			}

			.divide .left p {
				text-align: left;
			}
		}
	}
}