factory-discussions {
	display: flex;

	section.left-panel {
		width: 225px;
		padding-top: 25px;

		h4 {
			text-transform: uppercase;
			font-size: 10px;
			opacity: .6;
			margin-top: 25px;
			padding-left: 15px;
		}

		ul.topics {

			li.topic {
				font-size: 14px;
				line-height: 1;
				padding: 5px;
				border-radius: 5px;
				margin-bottom: 2px;
				margin-top: 2px;
				cursor: pointer;
				transition: background-color .3s;
				padding-left: 15px;
				padding-right: 15px;

				.message-avatar {
					width: 25px;
					height: 25px;
					margin-right: 10px;
					background-size: cover;
					background-position: center;
					border-radius: 50%;
					box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.32);
					float: left;
				}

				.message-name {
					display: inline-block;
				}

				.message-location {
					text-transform: uppercase;
					font-size: 10px;
					opacity: .6;
					margin-top: 2px;
					display: inline-block;
				}

				&:hover {
					background-color: darken(#930807, 3%);
				}
			}
		}
	}

	section.right-panel {
		width: calc(100vw - 225px);

		.main-layout {
			padding: 0px;
			display: block;
		}

		.topic-summary {
			background-color: #e7e7e7;
			width: 100%;
			height: 75px;
			display: flex;
			align-items: center;
			padding-left: 2%;
			padding-right: 2%;

			.recipient {
				display: flex;
				align-items: flex-end;
				margin-right: 2%;


				.recipient-avatar {
					width: 35px;
					height: 35px;
					margin-right: 10px;
					background-size: cover;
					background-position: center;
					border-radius: 50%;
					box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.32);
				}
	
				.recipient-details {
					display: flex;
					flex-direction: column;

					.recipient-name {
						font-size: 20px;
						font-weight: 900;
					}
		
					.recipient-location {
						text-transform: uppercase;
						font-size: 12px;
						opacity: .6;
						margin-top: 2px;
						display: inline-block;
					}
				}
			}

			&.multiple-recipients {
				padding-left: 5px;

				.recipient {
					flex-direction: column;
					align-items: center;
					border-right: 1px solid #d0cece;
					padding-right: 10px;
					padding-left: 10px;
					transition: background-color .3s;
					border-radius: 5px;
					padding:6px;
					cursor: pointer;
					margin-right: 0px;

					.recipient-avatar {
						width: 25px;
						height: 25px;
						margin-bottom: 5px;
					}

					.recipient-name {
						font-size: 14px;
						font-weight: 600;
						max-width: 125px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						text-align: center;
					}

					.recipient-location {
						font-size: 11px;
						max-width: 125px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						text-align: center;
					}

					&:last-child {
						border-right: 0px;
					}

					&:hover {
						background-color: #d0cece;
					}
				}
			}
		}

		.chatroom {
			display: flex;
			height: calc(100vh - 200px);
			overflow: hidden;
			position: relative;
			width: 100%;
			width: calc(100% - 300px);
			flex-direction: column;

			.chat {
				display: flex;
				padding: 8px 0;
				padding-left: 20px;

				.chat-avatar {
					width: 35px;
					height: 35px;
					margin-right: 10px;
					background-size: cover;
					background-position: center;
					border-radius: 50%;
					box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.32);
				}

				.chat-header {
					display: flex;
					align-items: center;

					.chat-sender {
						font-size: 15px;
						font-weight: 600;
						max-width: 125px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						text-align: center;
						line-height: 1.1;
					}

					.chat-timestamp {
						color: #717274;
						font-size: 12px;
						max-width: 125px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						text-align: center;
						margin-left: 5px;
					}
				}

				.chat-body {
					line-height: 1.46668;
					color: #2c2d30;
					display: inline-block;
					width: 100%;
					word-wrap: break-word;
					font-size: 15px;

					.callout {
						cursor: pointer;
						background-color:rgba(147, 8, 7, 0.10);
						color: #c20100;
						display: inline-block;
						border-radius: 4px;
						padding-right: 4px;
						padding-left: 4px;
					}
				}
			}

			.divider {
				border-bottom: 0;
				border-top: 1px solid #e8e8e8;
				position: relative;
				margin: 28px 0;

				span {
					background: #fff;
					border-radius: 24px;
					display: inline-block;
					padding: .25rem .75rem;
					position: relative;
					z-index: 201;
					position: absolute;
					top: -10px;
					left: 50%;
					transform: translateX(-50%);
					font-weight: 700;
					color: #2c2d30;
					cursor: default;
					font-size: 14px;
				}
			}
		}

		.chat-input {
			height: 75px;
			background-color: yellowgreen;
			position: absolute;
			bottom: -75px;
			width: calc(100% - 300px);
			display: flex;
		}
	}
}