member-onboarding {
	background-color: rgb(218, 218, 218);
	display: flex;
	justify-content: center;
	align-items: center;
    min-height: 100vh;
    flex-direction: column;

    section.onboarding1 {

        &.is-submitted {
            display: none;
        }
    }

    section.onboarding2 {
        display: none;

        &.is-ready {
            display: flex;
        }
        &.is-submitted {
            display: none;
        }
    }
    h1 {
        font-weight: 900;
        font-size: 36px;
        margin-bottom: 24px;
    }

    form {
        display: flex;
        flex-direction: column;
        max-width: 500px;

        .name {
            display: flex;
            justify-content: space-between;

            .first-name {
                width: 48%;
            }

            .last-name {
                width: 48%;
            }
        }

        .date {
            display: flex;
            justify-content: space-between;

            input {
                width: 31%;
                margin-bottom: 18px;
            }
        }

        .country {
            display: flex;
            flex-direction: column;
        }

        .datesex {
            display: flex;
            justify-content: space-between;

            .dob {
                width: 48%;
            }
            
            .gender {
                width: 48%;
            }
        }
    }

    label {
        color: #848484;
        font-weight: 400;
        font-size: 10px;
        margin-bottom: 5px;
        text-transform: uppercase;
        font-weight: 600;
        display: inline-block;
    }

    input {
        border: 0;
        background-color: #FFF;
        box-shadow: none;
        font-size: 14px;
        line-height: 22px;
        padding: 10px;
        width: 100%;
        border: 0;
        color: #7f7f7f;
        font-size: 14px;
        height: auto;
        border-radius: 2px;
        -webkit-appearance: none;
        margin-bottom: 12px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;

        &.is-error {
            background-color: #F6DCDD;
        }
    }

    select {
        background-color: #fff;
        font-size: 14px;
        line-height: 22px;
        cursor: pointer;
        padding: 10px;
        width: 100%;
        height: auto;
        border: 0;
        border-radius: 2px;
        background-position: 95% center;
        background-repeat: no-repeat;
        color: #7f7f7f;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appeareance: none;
        margin-bottom: 12px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAYAAAD+Bd/7AAAAU0lEQVR42jXMUQnAMAxF0Zjo19xUwEzUxKREy6AOJqBWBoX0BW4+DoSbEHP3Jq9cYol5SjOGkJUBizbzoMtP+BC0Xi9v2RLYNMtlGRIYNA4ED6wcTfpq/NY0RmUAAAAASUVORK5CYII=);

        &.is-error {
            background-color: #F6DCDD;
        }
    }

    .button {
        color: white;
        background-color: #920807;
        text-align: center;
        padding: 15px;
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        margin-top: 12px;
        border-radius: 3px;
        transition: background-color .3s;
        cursor: pointer;

        &:hover {
            background-color: darken(#920807, 10%);
        }
    }
}