main-home {
	background-color: #1a1b1f;
	background-image: radial-gradient(circle at top right, #444751, #232428 50%);
	display: block;
	min-height: 100vh;
	padding-left: 150px;

	section.hero {
		background-image: url(/assets/images/factory.svg);
		background-size: 50%;
		background-position: right center;
		background-repeat: no-repeat;
		max-width: 1400px;
		margin: auto;
		padding-left: 5%;
		padding-right: 5%;
		opacity: 0;
		min-height: 350px;

		.cta {
			display: flex;
			height: 40vh;
			max-height: 600px;

			.text {
				display: flex;
				flex-direction: column;
				width: 75%;
				justify-content: center;

				h1 {
					background-color: black;
					color: #7c919e;
					align-self:flex-start;
					padding: 5px;
					font-size: 14px;
					margin-bottom: 10px;
					opacity: 0;
				}
	
				h2 {
					font-size: 64px;
					font-weight: 900;
					color: white;
					margin-bottom: 25px;
					opacity: 0;
				}

				p {
					font-size: 18px;
					line-height: 1.4;
					color: #7c919e;
					max-width: 60%;
					margin-bottom: 25px;
					opacity: 0;
				}

				.buttons {
					display: flex;
					opacity: 0;

					.button {
						background-color: #930807;
						text-align: center;
						min-width: 100px;
						color: white;
						padding: 10px 20px;
						border-radius: 3px;
						font-size: 12px;
						font-weight: 300;
						box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.75);
						cursor: pointer;
						transition: background-color .3s, color .3s;
						margin-right: 25px;

						&:hover {
							background-color: darken(#930807, 10%);
						}

						&.ghost {
							background-color: transparent;
							border: solid #7c919e 1px;
							color: #7c919e;

							&:hover {
								background-color: darken(#7c919e, 10%);
								color: white;
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 1800px) {
			padding-left: 3%;
			padding-right: 3%;
		}
	}

	section.recommended {
		margin:auto;
		margin-left: 5%;
		margin-right: 5%;
		margin-bottom: 50px;

		h3 {
			font-size: 18px;
			font-weight: 900;
			color: white;
			margin-bottom: 10px;
			display: inline-block;
			margin-right: 25px;
			opacity: 0;
		}
		
		.factories {
			display: flex;
			justify-content: space-between;
		}

		.factory {
			width: 20%;
			height: 240px;
			background-size: cover;
			background-color: #121317;
			padding: 15px;
			border-radius: 4px;
			margin-left: 0px;
			background-size: auto 100%;
			background-repeat: no-repeat;
			background-position: center;
			margin-left: 15px;
			margin-right: 15px;
			cursor: pointer;
			opacity: 0;

			.tag {
				background-color: black;
				color: #7c919e;
				align-self: flex-start;
				padding: 5px;
				font-size: 12px;
				margin-bottom: 10px;
				display: inline;
				border-radius: 4px;
				padding-left: 8px;
				padding-right: 8px;
			}

			.genre {
				color: #7c919e;
				font-size: 11px;
				margin-bottom: 5px;
			}

			.factory-name {
				color: white;
				font-weight: 700;
				margin-bottom: 5px;
			}

			.factory-blurb {
				color: #7c919e;
				font-size: 12px;
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				line-height: 1.2;
			}

			.info {
				margin-top: 135px;
				display: flex;
			}

			.stats {
				display: flex;
				flex-direction: column;
				align-items: center;

				img.logo {
					width:40px;
					height: 40px;
					background-size: cover;
					border-radius: 50%;
					box-shadow: 0 0 0 2pt white;
					margin-bottom: 5px;
				}
				.factory-followers {
					font-size: 12px;
					color: white;
					font-weight: 400;
					text-align: right;
					margin-top: 8px;
					letter-spacing: 1px;
					background-position: center;
				}
			}

			.text {
				width: calc(100% - 40px);
				padding-left: 15px;
				max-height: 68px;
				overflow: hidden;
			}

			&:first-child {
				margin-left: 0px;
			}

			&:last-child {
				margin-right: 0px;
			}

			&:hover {
				box-shadow: 6px 6px 13px 0px rgba(0,0,0,0.75);
				background-size: auto 103%;
				transition: box-shadow .3s, background-size 1s;
			}
		}

		@media screen and (max-width: 1800px) {
			margin-left: 3%;
			margin-right: 3%;
		}
	}

	section.category-select {
		display: flex;
		margin:auto;
		margin-left: 5%;
		margin-right: 5%;
		padding-bottom: 50px;
		opacity: 0;
		flex-wrap: wrap;

		.select {
			background-color: black;
			color: white;
			padding: 10px;
			padding-left: 15px;
			padding-right: 15px;
			border-radius: 5px;
			margin-right: 10px;
			margin-bottom: 10px;
			cursor: pointer;
			transition: background-color .3s;

			&:hover {
				background-color: lighten(black, 10%);
			}
		}

		@media screen and (max-width: 1800px) {
			margin-left: 3%;
			margin-right: 3%;
		}
	}

	section.category {
		margin:auto;
		margin-left: 5%;
		margin-right: 5%;
		padding-bottom: 50px;

		.category-head {
			display: flex;
			
			select {
				font-size: 12px;
				line-height: 1;
				background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
				linear-gradient(to bottom, #000 0%,#000 100%);
				background-color: #000;
				color: #aaa;
				border: none;
				top: -3px;
				font-weight: 300;
				padding: 0.4em 1.8em .4em .8em;
				opacity: 0;

				&:focus {
					border-color: #aaa;
					box-shadow: none;
					box-shadow: 0 0 0 3px -moz-mac-focusring;
				}
			}
		}

		h3 {
			font-size: 18px;
			font-weight: 900;
			color: white;
			margin-bottom: 10px;
			display: inline-block;
			margin-right: 15px;
			opacity: 0;
		}
		
		.factories {
			display: flex;
			justify-content: space-between;
		}

		.factory {
			width: 13%;
			height: 240px;
			background-size: cover;
			background-color: #121317;
			padding: 15px;
			border-radius: 4px;
			background-size: cover;
			background-position: center;
			margin-left: 10px;
			margin-right:10px;
			opacity: 0;

			.tag {
				background-color: black;
				color: #7c919e;
				align-self: flex-start;
				padding: 5px;
				font-size: 12px;
				margin-bottom: 10px;
				display: inline;
				border-radius: 4px;
				padding-left: 8px;
				padding-right: 8px;
			}

			.genre {
				color: #7c919e;
				font-size: 11px;
				margin-bottom: 5px;
			}

			.factory-name {
				color: white;
				font-weight: 700;
				margin-bottom: 5px;
			}

			.factory-blurb {
				color: #7c919e;
				font-size: 12px;
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				line-height: 1.2;
			}

			.info {
				margin-top: 135px;
				display: flex;
			}

			.stats {
				display: flex;
				flex-direction: column;
				align-items: center;

				img.logo {
					width:40px;
					height: 40px;
					background-size: cover;
					border-radius: 50%;
					box-shadow: 0 0 0 2pt white;
					margin-bottom: 5px;
					background-position: center;

				}

				.factory-followers {
					font-size: 12px;
					color: white;
					font-weight: 400;
					text-align: right;
					margin-top: 8px;
					letter-spacing: 1px;
				}
			}


			.text {
				width: calc(100% - 40px);
				padding-left: 15px;
				overflow: hidden;
			}

			&:first-child {
				margin-left: 0px;
			}

			&:last-child {
				margin-right: 0px;
			}
		}

		@media screen and (max-width: 1800px) {
			margin-left: 3%;
			margin-right: 3%;
		}
	}

	&.is-ready {

		section.hero {
			opacity: 1;
			transition: opacity 1s;
	
			.cta {
	
				.text {
	
					h2 {
						opacity: 1;
						transition: opacity 1s .4s;
					}

					h1 {
						opacity: 1;
						transition: opacity 1s .3s;
					}

					p {
						opacity: 1;
						transition: opacity 1s .5s;
					}

					.buttons {
						opacity: 1;
						transition: opacity 1s .6s;
					}
				}
			}
		}

		section.category .factory, section.recommended .factory {
			opacity: 1;

			&:nth-child(1) {
				transition: box-shadow .3s, background-size .3s, opacity .5s;
			}
			&:nth-child(2) {
				transition: box-shadow .3s, background-size .3s, opacity .5s .1s;
			}
			&:nth-child(3) {
				transition: box-shadow .3s, background-size .3s, opacity .5s .2s;
			}
			&:nth-child(4) {
				transition: box-shadow .3s, background-size .3s, opacity .5s .3s;
			}
			&:nth-child(5) {
				transition: box-shadow .3s, background-size .3s, opacity .5s .4s;
			}
			&:nth-child(6) {
				transition: box-shadow .3s, background-size .3s, opacity .5s .5s;
			}
			&:nth-child(7) {
				transition: box-shadow .3s, background-size .3s, opacity .5s .6s;
			}
			&:nth-child(18) {
				transition: box-shadow .3s, background-size .3s, opacity .5s .7s;
			}
		}
		section.category h3, section.recommended h3, section.category select, section.recommended select {
			opacity: 1;
			transition: opacity .5s;
		}

		section.category-select {
			opacity: 1;
			transition: opacity .5s;
		}
	}


	@media screen and (max-width: 1400px) {
		
		section.category .factory {
			width: 16%;

			&:nth-child(7) {
				display: none;
			}
		}
	}


	@media screen and (max-width: 1280px) {
		
		section.hero .cta .text {
			h2 {
				font-size: 50px;
				margin-bottom: 8px;
			}

			p {
				font-size: 16px;
			}
		}

		section.recommended .factory {
			width: 25%;

			&:nth-child(5) {
				display: none;
			}
		}

		section.category .factory {
			width: 20%;
			padding: 10px;

			.text {
				padding-left: 10px;
				width: calc(100% - 20px);
			}

			.stats img.logo {
				width: 30px;
				height: 30px;
			}

			&:nth-child(6) {
				display: none;
			}
		}


	}

	@media screen and (max-width: 1024px) {
		
		section.hero .cta .text {
			h2 {
				font-size: 40px;
			}
		}

		section.recommended .factory {
			margin-right: 0px;
			width: 33%;
			padding: 5px;

			.text {
				padding-right: 5px;
			}

			.stats img.logo {
				width: 30px;
				height: 30px;
			}
			
			&:nth-child(4) {
				display: none;
			}
		}
		
		section.category-select  {

			.select {
				font-size: 14px;
			}
		}

		section.category .factory {
			margin-left: 5px;
			margin-right: 5px;

			.factory-blurb {
				font-size: 11px;
			}

			.info {
				margin-top: 125px;
			}

			&:nth-child(6) {
				display: none;
			}
		}
	}

	@media screen and (max-width: 850px) {

		section.category .factory {
			width: 25%;

			&:nth-child(5) {
				display: none;
			}
		}
	}

	@media screen and (max-width: 720px) {
		padding-left: 0px;

		section.recommended {

			.factories {
				max-width: 100vw;
				overflow: scroll;
			}

			.factory {
				min-width: 200px;
				padding: 15px;

				.text {
					padding-right: 15px;
				}
	
				.stats img.logo {
					width: 40px;
					height: 40px;
				}

				&:nth-child(4), &:nth-child(5) {
					display: block;
				}
			}
		}

		section.category {

			.factories {
				max-width: 100vw;
				overflow: scroll;
			}

			.factory {
				min-width: 160px;

				&:nth-child(5), &:nth-child(6), &:nth-child(7) {
					display: block;
				}
			}
		}
	}

	@media screen and (max-width: 600px) {
		
		section.hero .cta .text {
			width: 100%;

			.buttons {
				justify-content: center;
			}
		}


	}

	@media screen and (max-width: 500px) {
		
		section.hero  {
			background: transparent;
			margin-bottom: 50px;

			.cta .text {
				p {
					max-width: 100%;
				}

				h2 {
					font-size: 30px;
				}
			}
		}
	}
}