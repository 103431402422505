member-profile {
    min-height: 100vh;
    display: flex;
    background-size: cover;
    background-position: top;
    background-attachment: fixed;

    section.profile {
        width: 150px;
        min-height: calc(100vh - 50px);
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 25px;
        border-right: solid 1px lightgray;
        background-color: rgba(255, 255, 255, 0.43);

        .member-avatar {
            width: 100px;
            height: 100px;
            display: inline-block;
            background-size: cover;
            background-position: center;
            border-radius: 5px;
            border: 1px double #000;
            outline-offset: 1px;
            margin-bottom: 15px;

            &:after {
                content: "";
                height: 108px;
                width: 108px;
                border-radius: 5px;
                border: 2px solid #000;
                display: inline-block;
                top: -5px;
                left: -5px;
                box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
            }
        }

        .member-name {
            font-weight: 900;
            letter-spacing: -1px;
            margin-bottom: 5px;
            font-size: 18px;
            text-align: center;
        }

        .friend-count {
            color: grey;
            font-size: 14px;
            margin-left: 2px;
            letter-spacing: -.5px;
            font-weight: 600;
            margin-bottom: 6px;

            span {
                font-weight: 300;
            }
        }

        .button {
            background-color: #930807;
            color: white;
            padding: 3px 5px;
            border-radius: 3px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
            box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.75);
            cursor: pointer;
            transition: background-color .3s;

            &:hover {
                background-color: darken(#930807, 5%);
            }
        }

        hr {
            width: 80%;
            margin-top: 15px;
            margin-bottom: 15px;
            border-bottom: .5px solid #d2d2d2;
            border-top: .5px solid #d2d2d2;
        }

        ul.member-navigation, ul.member-navigation, ul.member-help {

            li {
                text-transform: uppercase;
                font-size: 14px;
                margin-bottom: 10px;
                cursor: pointer;
                transition: color .3s;

                &:last-child {
                    margin-bottom: 0;
                }

                &.selected {
                    font-weight: 700;
                }

                &:hover {
                    color: #930807;
                }
            }
        }
        ul.member-help {
            position: absolute;
            bottom: 50px;
            left: 25px;
        }
    }

    section.member-experience {
        min-width: calc(100% - 150px);
        min-height: calc(100% - 50px);
    }
}