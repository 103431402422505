/*-----------------------------------------------------*\

	*Filename:		base.scss
	*Description: 	contains the baseline reset and
					grids for the project
	*Version:		1.0.0(2015-03-10)
	*Website:		http://schonne.com
	*Author:		Schonne Eldridge

 ===TABLE OF CONTENTS:===================================

	$_RESET			reset.css

\*------------------------------------------------------*/


/*-----------------------------------*\
		$_RESET
\*-----------------------------------*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}

html {
	min-height: 100vh;
}

body {
	line-height: 1;
	margin: 0;
	padding: 0;
	min-height: 100vh;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
	content: "";
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

*, *:before, *:after {
	margin: 0;
	padding: 0;
	position: relative;
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}

.clearfix:before, .clearfix:after {
	content: " ";
	display: table;
}

.clearfix:after {
	clear: both;
}

.content {
	width: 1600px;
	margin: auto;
	display: block;

	@media screen and (max-width: 1600px) {
		width: 100%;
	}
}


input:focus, select:focus, textarea:focus, button:focus {
	outline: none;
}