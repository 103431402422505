factory-profile {
	min-height: 100vh;
	display: flex;
	background-color: white;
	background-size: cover;
	background-position: top;
	background-attachment: fixed;

	section.profile {
		width: 150px;
		min-height: 100vh;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding-top: 25px;
		background-color: rgba(255, 255, 255, 0.43);
		position: fixed;
		background-color: #121317;

		.factory-avatar {
			width: 100px;
			height: 100px;
			display: inline-block;
			background-size: cover;
			background-position: center;
			border-radius: 5px;
			border: 1px double #000;
			outline-offset: 1px;
			margin-bottom: 15px;
			opacity: 0;
			transition: opacity .8s 0s;

			&:after {
				content: "";
				height: 108px;
				width: 108px;
				border-radius: 5px;
				border: 2px solid #000;
				display: inline-block;
				top: -5px;
				left: -5px;
				box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
			}
		}

		.factory-name {
			font-weight: 700;
			letter-spacing: -1px;
			margin-bottom: 5px;
			font-size: 18px;
			text-align: center;
			color: white;
			transform: translate(-25px, 0px);
			transition: transform .5s .2s, opacity .8s .2s;
			opacity: 0;
		}

		.member-count {
			color: grey;
			font-size: 14px;
			margin-left: 2px;
			letter-spacing: -.5px;
			font-weight: 600;
			margin-bottom: 6px;
			cursor: pointer;
			transition: color .3s;
			transform: translate(-25px, 0px);
			transition: transform .5s .4s, opacity .8s .4s;
			opacity: 0;

			span {
				font-weight: 300;
			}

			&:hover {
				color: lighten(grey, 10%);
			}
		}

		.button {
			background-color: #930807;
			color: white;
			padding: 3px 5px;
			border-radius: 3px;
			text-transform: uppercase;
			font-size: 12px;
			font-weight: 600;
			box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.75);
			cursor: pointer;
			transition: background-color .3s;
			display: inline-block;
			transform: translate(-25px, 0px);
			transition: transform .5s .6s, opacity .8s .6s;
			opacity: 0;

			&:hover {
				background-color: darken(#930807, 5%);
			}
		}

		hr {
			width: 0%;
			margin-top: 15px;
			margin-bottom: 15px;
			border-bottom: .5px solid #d2d2d2;
			border-top: .5px solid #d2d2d2;
			opacity: 0;
			transition: width .5s .4s, opacity 0s .4s;
		}

		.factory-bottom {
			min-height: calc(100vh - 250px);
		}

		.factory-top .back-arrow {
			display: none;
		}

		ul.factory-navigation, ul.member-navigation, ul.factory-help {

			li {
				color:#7c919e;
				font-size: 14px;
				margin-bottom: 10px;
				cursor: pointer;
				transition: color .3s;
				transform: translate(-25px, 0px);
				opacity: 0;

				&:last-child {
					margin-bottom: 0;
				}

				&.is-selected {
					font-weight: 700;
					color: white;
				}

				&:hover {
					color: #d9d9d9;
				}
			}
		}

		ul.factory-help {
			position: absolute;
			bottom: 50px;
		}
	}

	section.factory-experience {
		min-width: calc(100% - 150px);
		min-height: calc(100% - 50px);
		margin-left: 150px;
	}

	&.is-ready {
		section.profile {
			.factory-avatar {
				opacity: 1;
			}

			.factory-name, .member-count, .button {
				opacity: 1;
				transform: translate(0px, 0px);
			}

			hr {
				width: 80%;
				opacity: 1;
			}

			ul.factory-navigation, ul.member-navigation, ul.factory-help {

				li {
					opacity: 1;
					transform: translate(0px, 0px);

					&:nth-child(1) {
						transition: transform .5s .2s, opacity .8s .2s;
					}
					&:nth-child(2) {
						transition: transform .5s .3s, opacity .8s .3s;
					}
					&:nth-child(3) {
						transition: transform .5s .4s, opacity .8s .4s;
					}
					&:nth-child(4) {
						transition: transform .5s .5s, opacity .8s .5s;
					}
					&:nth-child(5) {
						transition: transform .5s .6s, opacity .8s .6s;
					}
					&:nth-child(6) {
						transition: transform .5s .7s, opacity .8s .7s;
					}
					&:nth-child(7) {
						transition: transform .5s .8s, opacity .8s .8s;
					}
					&:nth-child(8) {
						transition: transform .5s .9s, opacity .8s .9s;
					}
				}
			}
		}
	}

	@media screen and (max-width: 720px) {
		height: 50px;
		max-height: 50px;
		min-height: 50px;

		section.profile {
			position: fixed;
			width: 100vw;
			top: 0px;
			height: 50px;
			padding: 0px;
			z-index: 1;
			height: 100px;
			max-height: 100px;
			min-height: 100px;

			hr {
				display: none;
			}

			.factory-top {
				width: 100vw;
				display: flex;
				max-height: 50px;
				padding-left: 3%;
				padding-right: 5%;
				align-items: center;

				.back-arrow {
					margin-right: 20px;
					display: inline-block;
				}

				.factory-avatar {
					height: 40px;
					width: 40px;
					margin-bottom: 0px; 
					margin-right: 10px;

					&:after {
						height: 48px;
						width: 48px;
					}
				}

				.factory-info {
					display: flex;
					flex-direction: column;
					height: 50px;
					justify-content: center;

					.factory-name {
						font-weight: 700;
					}
				}

				.button {
					margin-right: 0px;
					margin-left: auto;
					align-self: center;
				}
			}

			.factory-bottom {
				background-color: #1b1c22;
				width: 100vw;
				display: flex;
				align-items: center;
				height: 50px;
				overflow: scroll;
				padding-left: 5%;
				min-height: 50px;

				ul {
					display: flex;
					border-left: 1px solid white;

					li {
						margin-bottom: 0px;
						margin-right: 30px;

						&:first-child {
							padding-left: 25px;
						}
					}

					&.factory-help {
						position: relative;
						bottom: initial;
						left: initial;
					}

					&:first-child {
						border-left: none;

						li {
							padding-left: 0px;
						}
					}
				}
			}

		}

		section.factory-experience {
			margin-top: 50px;
			margin-left: 0px;
		}
	}
}

body[page="factory-profile"] {

	@media screen and (max-width: 720px) {
		global-navigation.is-ready.is-hidden {
			transform: translate(-0px, -50px);
		}
	}
}