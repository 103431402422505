global-navigation {
	position: fixed;
	width: 100vw;
	min-height: 100vh;
	background-color: #121317;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 10;
	padding-top: 25px;
	transition: none;

	.menu-buttons {
		display: none;
		position: relative;
		right: calc(50% - 25px);
		top: 18px;
	}

	svg.logo {
		margin-bottom: 15px;
		cursor: pointer;
		opacity: 0;
		transform: scale(4) translate(0px, calc(10vh - 25px));
		transition: none;
		

		&:hover {
			opacity: .8;
		}
	}

	.navigation {
		display: flex;
		margin-left: 0px;
		margin-right: 0px;

		li {
			margin-left: 5px;
			margin-right: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			transform: translate(-25px, 0px);
			opacity: 0;

			.avatar {
				width: 25px;
				height: 25px;
				display: inline-block;
				background-size: cover;
				background-position: center;
				border-radius: 50%;
				border: 1px solid black;
				cursor: pointer;
				transition: opacity .3s;
				&:hover {
					opacity: .7;
				}

			}

			img {
				max-width: 18px;
				max-height: 18px;
				cursor: pointer;
				transition: opacity .3s;

				&:hover {
					opacity: .3;
				}
			}
		}

		&.is-hidden {
			display: none;
		}

		&.global {
			flex-direction: column;
			color: #7c919e;
			font-size: 14px;
			text-align: left;
			margin-top: 25px;
			align-items: baseline;
			width: 110px;
			margin-bottom: 10px;

			li {
				margin-bottom: 15px;
				cursor: pointer;
				transition: color .3s;

				&:hover {
					color: darken(white, 15%);
				}
			}

			&.small {

				li {
					font-size: 10px;
				}
			}
		}
	}

	hr {
		width: 100px;
		border-top: 1px solid #7c919e;
		border-bottom: none;
		display: none;
	}

	.button {
		background-color: #930807;
		text-align: center;
		min-width: 100px;
		color: white;
		padding: 3px 5px;
		border-radius: 3px;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 600;
		box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.75);
		cursor: pointer;
		-webkit-transition: background-color .3s;
		transition: background-color .3s;
		display: none;

		&:hover {
			background-color: darken(#930807, 5%);
		}
	}

	&.is-animating {
		transform: translate(calc(-100vw + 151px), 0px);
		transition: transform 1s 4s;

		svg.logo {
			opacity: 1;
			transform: translate(calc(50vw - 76px), 0px) scale(1);
			transition: transform 1s 4s, opacity 3s 1s;
		}
	}

	&.is-ready {
		width: 150px;
		transform: translate(0px, 0px);
		transition: none;

		svg.logo {
			transform: translate(0px, 0px);
			transition: none;
			opacity: 1;
		}

		.navigation li {
			transform: translate(0px, 0px);
			opacity: 1; 

			&:nth-child(1) {
				transition: transform .5s 0s, opacity .8s 0s;
			}
			&:nth-child(2) {
				transition: transform .5s .2s, opacity .8s .2s;
			}
			&:nth-child(3) {
				transition: transform .5s .4s, opacity .8s .4s;
			}
			&:nth-child(4) {
				transition: transform .5s .6s, opacity .8s .6s;
			}
			&:nth-child(5) {
				transition: transform .5s .8s, opacity .8s .8s;
			}
		}

		&.is-hidden {
			transform: translate(-150px, 0px);
			transition: transform .5s;
	
			svg.logo {
				transform: scale(0.6) translate(176px, -57px);
				transition: transform .5s;
			}
		}
	}

	@media screen and (max-width: 720px) {

		svg.logo {
			transform: scale(2.5) translate(0px, calc(15vh - 25px));
		}

		&.is-animating {
			transform: translate(0px, calc(-100vh + 50px));
			transition: transform 1s 4s;
	
			svg.logo {
				opacity: 1;
				transform: translate(0px, calc(100vh - 89px)) scale(.625);
				transition: transform 1s 4s, opacity 3s 1s;
			}
		}

		&.is-ready {
			min-height: 50px;
			max-height: 50px;
			height: 50px;
			width: 100vw;
			padding: 0px;
			margin: 0px;
			overflow: hidden;
			transition: height .3s, max-height .3s;

			.menu-buttons {
				display: inline-block;

				img.close {
					display: none;
				}
			}

			svg.logo {
				position: absolute;
				width: 50px;
				height: 50px;
				transform: translate(0px, 0px);
				margin-bottom: 0px;
				transition: transform 0s;
				opacity: 1;
			}

			.menu.global {
				transform: translate(0px, 75%);
				display: flex;
				flex-direction: column;
				align-items: center;

				li {
					margin-bottom: 25%;
					transform: translate(-25px, 0px);
					opacity: 0;
				}
			}
			.navigation.global {
				font-size: 20px;
				align-items: center;
				margin: 0px;
				padding-bottom: 25%;
				margin-bottom: 25%;
				border-bottom: 1px solid #383838;

				&.small {
					padding-top: 25%;
					border-bottom: 0px;
					margin-bottom: 0px;

					li {
						font-size: 16px;
					}
				}
			}

			&.is-open {
				max-height: calc(100vh - 50px);
				height: calc(100vh - 50px);
				transition: height .3s, max-height .3s;

				.menu-buttons {

					img.close {
						display: inline-block;
					}
					img.hamburger {
						display: none;
					}
				}

				svg.logo {
					transform: scale(2) translate(0px, 100%);
					transition: transform 1s;
				}

				.navigation li {
					transform: translate(0px, 0px);
					opacity: 1; 
		
					&:nth-child(1) {
						transition: transform .5s .3s, opacity .8s .3s;
					}
					&:nth-child(2) {
						transition: transform .5s .4s, opacity .8s .4s;
					}
					&:nth-child(3) {
						transition: transform .5s .5s, opacity .8s .5s;
					}
					&:nth-child(4) {
						transition: transform .5s .8s, opacity .8s .8s;
					}
					&:nth-child(5) {
						transition: transform .5s .9s, opacity .8s .9s;
					}
				}
			}
		}
	}

	@media screen and (max-height: 750px) {
		&.is-ready .menu.global {
			transform: translate(0px, 54%);
		}
	}

	@media screen and (max-height: 625px) {
		&.is-ready .menu.global {
			transform: translate(0px, 35%);
		}

		&.is-ready.is-open svg.logo {
			transform: scale(2) translate(0px, 50%);
		}
	}

	@media screen and (max-height: 565px) {
		&.is-ready .menu.global {
			transform: translate(0px, 40%);
		}
		&.is-ready .navigation.global {
			padding-bottom: 15%;
			margin-bottom: 15%;
		}

		&.is-ready .navigation.global.small {
			padding-top: 15%;
		}
	}
	
}
