factory-about {
	display: flex;

	section.left-panel {
		transform: translate(0%, -100%);
		transition: transform .5s .3s;

		.profile-banner {
			height: 450px;
			background-size: cover;
			background-position: center;
			position: relative;
			width: 250px;
			box-shadow: inset 0 0 100px black;
			opacity: 0;
			transition: opacity 1s .6s;
		}
	
		.profile-facts {
			padding: 5%;
	
			.profile-name {
				font-size: 22px;
				text-align: center;
				font-weight: 900;
				margin-bottom: 16px;
				opacity: 0;
				transition: opacity 1s .8s;
			}
	
			.profile-summary {
				font-size: 14px;
				line-height: 1.2;
				padding-bottom: 8px;
				opacity: 0;
				transition: opacity 1s .8s;
	
				p {
					margin-bottom: 16px;
					color: rgba(255, 255, 255, 0.7);
					opacity: 0;
					transition: opacity 1s .9s;
				}
			}
	
			ul.social {
				border-top: 1px solid rgba(255, 255, 255, 0.34);
				border-bottom: 1px solid rgba(255, 255, 255, 0.34);
				padding-top: 10px;
				padding-bottom: 10px;
				margin-bottom: 50px;
				width: 0px;
				opacity: 0;
				transition: width .3s 1s;

				li {
					opacity: 0;
				}
			}
	
			img.profile-video {
				margin-bottom: 10px;
				margin-top: 10px;
				width: 35px;
				height: 35px;
				cursor: pointer;
				transition: opacity .3s;
				position: relative;
				left: 50%;
				transform: translateX(-50%);
	
				&:hover {
					opacity: .6;
				}
			}
	
			.profile-stats {
				display: flex;
				flex-direction: column;
	
				label {
					text-transform: uppercase;
					font-size: 12px;
					font-weight: 600;
					color: #fff;
					margin-bottom: 8px;
				}
	
				a, p {
					color: rgba(255, 255, 255, 0.7);
					text-decoration: none;
					font-size: 14px;
					display: inline-block;
					align-self: start;
	
					p.title {
						text-transform: uppercase;
						font-size: 10px;
						margin-top: 2px;
						color: rgba(255, 255, 255, 0.5);
						display: block;
					}
				}
			}
		}
	}
	
	section.right-panel {
		background-color: #f4f4f4;

		.main-layout {
			width: 100%;
			padding-top: 0px;
			padding-left: 0px;
			padding-bottom: 2%;
			display: flex;
			flex-direction: column;

			.current-status {
				background-color: #121317;
				width: 98%;
				padding: 2%;
				padding-bottom:1%;
				align-self: self-end;
				margin-bottom: 2%;
				border-bottom-right-radius: 4px;
				transform: translate(0%, -100%);
				transition: transform .5s .3s;

				h2 {
					font-weight: 700;
					text-transform: uppercase;
					font-size: 14px;
					margin-bottom: 16px;
					color: white;
				}

				.status-container {
					display: flex;
					flex-direction: column;

					.status-update {
						opacity: 0;
						transition: opacity .5s .8s;

						.status-date {
							font-size: 12px;
							color: grey;
							font-weight: bold;
							margin-bottom: 6px;
						}
		
						.status {
							line-height: 1.3;
							color: rgba(255, 255, 255, 0.7);
							font-size: 14px;
						}
					}
	
					.promotions {
						margin-top: 8px;
						padding-top: 8px;
						border-top: solid #dbdbdb 1px;
						display: flex;
						opacity: 0;
						transition: opacity .5s .8s;
	
						.promotion {
							border-radius: 4px;
							display: flex;
							padding: 1%;
							max-width: 32%;
							margin-right: 1.3333333%;
	
							.promotion-image {
								width: 100px;
								height: 100px;
								background-size: cover;
								margin-right: 8px;
							}
	
							.promotion-description {
								width: calc(100% - 100px);
	
								.promotion-title {
									font-size: 14px;
									font-weight: 700;
									margin-bottom: 8px;
									color: white;
								}
	
								.promotion-text {
									font-size: 12px;
									color: rgba(255, 255, 255, 0.7);
								}
	
								.promotion-button {
									border: #930807 solid 1px;
									text-align: center;
									color: #930807;
									padding: 2px 4px;
									border-radius: 3px;
									font-size: 12px;
									font-weight: 300;
									cursor: pointer;
									transition: background-color .3s, color .3s;
									margin-top: 10px;
									display: inline-block;
	
									&:hover {
										background-color: red;
									}
								}
							}
	
							&:last-child {
								margin-right: 0px;
							}
						}
					}
				}


				&.horizontal {
					
					.status-container {
						flex-direction: row;

						.status-update {
							width: 40%;
						}

						.promotions {
							flex-direction: column;
							width: 60%;
							border-top: none;
							border-left: 1px solid grey;
							margin-top: 0px;
							padding-top: 0px;
							margin-left: 15px;
							padding-left: 15px;

							.promotion {
								max-width: 100%;
								margin-right: 0px;
								margin-bottom: 10px;

								.promotion-image {
									width: 60px;
									height: 60px;
								}

								.promotion-title {
									margin-bottom: 5px;
								}

								.promotion-button {
									padding: 0px;
									border: none;
									font-weight: 700;
									margin-top: 0px;
									transition: color ,3s;

									&:hover {
										color: darken(#930807, 15%);
										background-color: transparent;
									}
								}
							}
						}
					}
				}
			}

			.announcements {
				background-color: white;
				border-radius: 4px;
				width: 96%;
				padding: 2%;
				align-self: self-end;
				margin-right: 2%;
				margin-left: 2%;
				opacity: 0;
				transition: opacity 1s .5s;

				h2 {
					font-weight: 700;
					text-transform: uppercase;
					font-size: 14px;
					margin-bottom: 16px;
					opacity: 0;
					transition: opacity 1s 1s;
				}

				.announcement {

					.announcement-image {
						background-size: cover;
						width: 100%;
						min-height: 200px;
						margin-bottom: 15px;
						border-radius: 4px;
					}

					.announcement-date {
						font-size: 12px;
						color: grey;
						font-weight: bold;
						margin-bottom: 6px;

						span.announcement-author {
							color: #930807;
							cursor: pointer;
							transition: color .3s;

							&:hover {
								color: darken(#930807, 15%);
							}
						}
					}

					.announcement-title {
						font-weight: 900;
						font-size: 27px;
						margin-bottom: 8px;
						color: #930807;
						transition: color .3s;
						cursor: pointer;

						&:hover {
							color: darken(#930807, 15%);
						}
					}

					.announcement-text {
						font-size: 14px;
						line-height: 1.3;
					}
				}

				.announcements-top {
					display: flex;
					margin-bottom: 50px;
					opacity: 0;
					transition: opacity 1s 1.5s;

					.announcement {
						width: 66%;
						margin-right: 1%;
					}

					.events {
						width: 33%;
						margin-left: 1%;
						padding-left: 10px;
						background-color: #f4f4f4;
						border-radius: 4px;
						padding-top: 15px;
						padding-right: 15px;

						.event {
							border-bottom: 1px solid #dadada;
							padding-bottom: 8px;
							margin-bottom: 8px;

							.event-date {
								font-size: 12px;
								color: grey;
								font-weight: bold;
								margin-bottom: 3px;
							}

							.event-title {
								font-weight: 700;
								font-size: 14px;
								color: #930807;
								margin-bottom: 6px;
								cursor: pointer;
								transition: color .3s;

								&:hover {
									color: darken(#930807, 15%);
								}
							}

							.event-attending {
								font-size: 12px;
								color: grey;
								margin-bottom: 6px;
							}

							&:last-child {
								border-bottom: none;
								margin-bottom: none;
							}
						}
					}
				}

				.announcements-bottom {
					display: flex;
					opacity: 0;
					transition: opacity .5s 2s;

					.announcement {
						width: 33.3333%;
						margin-right: 25px;

						.announcement-image {
							min-height: 150px;
						}

						.announcement-title {
							font-size: 16px;
						}

						.announcement-text {
							overflow: hidden;
							display: -webkit-box;
							-webkit-line-clamp: 5;
							-webkit-box-orient: vertical;
							line-height: 1.2;
						}

						&:last-child {
							margin-right: 0px;
						}
					}
				}
			}

		}

		.side-panel {
			margin-top: 60px;
			margin-left: 0px;
			margin-right: 0px;
			min-width: 200px;
			

			.whos-online {
				text-align: left;

				h2 {
					font-weight: 600;
					font-size: 10px;
					margin-bottom: 10px;
					margin-top: 25px;
					text-align: left;
					text-transform: uppercase;
					opacity: 0;
					transition: opacity .5s 1s;
				}

				.whos {

					.who {
						display: flex;
						border-bottom: 1px solid #cbcbcb;
						padding-bottom: 8px;
						margin-bottom: 8px;
						transform: translate(-25px, 0px);
						opacity: 0;

						.who-avatar {
							width: 35px;
							height: 35px;
							background-size: cover;
							border-radius: 50%;
							margin-right: 10px;
							background-position: center;
							cursor: pointer;
							transition: opacity .3s;

							&:hover {
								opacity: .8;
							}
						}

						.who-details {
							display: flex;
							flex-direction: column;

							.who-name {
								font-weight: 700;
								font-size: 14px;
								text-align: left;
								cursor: pointer;
								transition: color .3s;

								&:hover {
									color: #930807;
								}
							}

							.who-title {
								font-size: 12px;
								text-align: left;
							}

							.who-activity {
								font-size: 12px;
								text-align: left;
								color: #930807;
								font-style: italic;
								cursor: pointer;
								transition: color .3s;

								&:hover {
									color: darken(#930807, 15%);
								}
								
							}
						}

						&:last-child {
							border-bottom: none;
						}
					}
				}

				.button {
					text-align: center;
					min-width: 100px;
					padding: 5px 10px;
					border-radius: 3px;
					font-size: 12px;
					font-weight: 300;
					box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.75);
					cursor: pointer;
					transition: background-color .3s, color .3s;
					margin-right: 25px;
					background-color: transparent;
					border: solid #121317 1px;
					color: #121317;
					display: inline-block;
					margin-top: 10px;
				}
			}
		}
	}

	&.is-ready {

		section.left-panel {
			transform: translateY(0%);
	
			.profile-banner {
				opacity: 1;
			}

			.profile-facts {
		
				.profile-name {
					opacity: 1;
				}

				.profile-summary {
					opacity: 1;

					p {
						opacity: 1;
					}
				}

				ul.social {
					width: 100%;
					opacity: 1;

					li:nth-child(1) {
						opacity: 1;
						transition: opacity .3s 1.2s;
					}

					li:nth-child(2) {
						opacity: 1;
						transition: opacity .3s 1.3s;
					}
					
					li:nth-child(3) {
						opacity: 1;
						transition: opacity .3s 1.4s;
					}
					
					li:nth-child(4) {
						opacity: 1;
						transition: opacity .3s 1.5s;
					}
					
					li:nth-child(5) {
						opacity: 1;
						transition: opacity .3s 1.6s;
					}
					
					li:nth-child(6) {
						opacity: 1;
						transition: opacity .3s 1.7s;
					}
					li:nth-child(7) {
						opacity: 1;
						transition: opacity .3s 1.8s;
					}
				}
			}
		}

		section.right-panel {
	
			.main-layout {
	
				.current-status {
					transform: translateY(0%);

					.status-container .promotions, .status-container .status-update {
						opacity: 1;
					}
				}

				.announcements {
					opacity: 1;

					h2 {
						opacity: 1;
					}

					.announcements-top, .announcements-bottom {
						opacity: 1;
					}
				}
			}

			.side-panel {

				h2 {
					opacity: 1;
				}

				.whos {

					.who {
						transform: translate(0px, 0px);
						opacity: 1;
						transition: opacity .6s 2s, transform 1s 2s;

						&:nth-child(1) {
							transition: opacity .6s 1s, transform 1s 1s;
						}
						&:nth-child(2) {
							transition: opacity .6s 1.1s, transform 1s 1.1s;
						}
						&:nth-child(3) {
							transition: opacity .6s 1.2s, transform 1s 1.2s;
						}
						&:nth-child(4) {
							transition: opacity .6s 1.3s, transform 1s 1.3s;
						}
						&:nth-child(5) {
							transition: opacity .6s 1.4s, transform 1s 1.4s;
						}
						&:nth-child(6) {
							transition: opacity .6s 1.5s, transform 1s 1.5s;
						}
						&:nth-child(7) {
							transition: opacity .6s 1.6s, transform 1s 1.6s;
						}
						&:nth-child(8) {
							transition: opacity .6s 1.7s, transform 1s 1.7s;
						}
						&:nth-child(9) {
							transition: opacity .6s 1.8s, transform 1s 1.8s;
						}

					}
				}
			}
		}
	}

	@media screen and (max-width: 1200px) {
		section.right-panel {
			.main-layout {
				.announcements {
					.announcements-top {
						flex-direction: column;
						margin-bottom: 25px;
		
						.announcement { 
							width: 100%;
							margin-right: 0px;
							margin-bottom: 25px;
						}

						.events {
							width: 100%;
							margin-left: 0px;
						}
					}

					.announcements-bottom {
						flex-direction: column;

						.announcement {
							width: 100%;
							margin-right: 0px;
							display: flex;

							.announcement-image {
								width: 33%;
								margin-right: 15px;
								margin-top: 15px;
								margin-bottom: 15px;
							}

							.announcement-details {
								width: 67%;
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 1024px) {
		flex-direction: column;

		section.left-panel {
			width: calc(100vw - 150px);
			min-height: initial;
			flex-direction: initial;

			.profile-facts {
				padding: 25px;
				margin-top: 50px;
			}

			.profile-banner {
				min-width: 250px;
			}
		}

		section.right-panel {
			width: calc(100vw - 150px);

			.main-layout {
				.current-status.horizontal {
					transform: translate(-100%, 0%);

					.status-container {
						flex-direction: column;

						.status-update {
							width: 100%;
							margin-bottom: 10px;
						}

						.promotions {
							width: 100%;
						}
					}
				}
			}

			.side-panel {
				margin-top: 0px;
			}
		}

		&.is-ready section.right-panel .main-layout .current-status {
			transform: translate(0%, 0%);
		}
	}

	@media screen and (max-width: 720px) {


		section.left-panel {
			width: 100vw;
			min-height: initial;

			.profile-banner {
				width: 100vw;
				height: 200px;
				position: absolute;
			}

			.profile-facts {
				padding: 3%;
				background: linear-gradient(0deg, #930807 0%, #930807 34%,#121317 83%, rgba(18, 19, 23, 0) 100%);
				margin-top: 144px;

				.profile-name {
					font-size: 32px;
					margin-bottom: 25px;
				}
				// // logged in view
				// .profile-summary {
				// 	max-height: 0px;
				// 	overflow: hidden;
				// 	transition: max-height .5s;
				// 	padding-bottom: 0px;
				// }

				ul.social {
					margin-bottom: 0px;
				}
			}
		}
		section.right-panel {
			width: 100vw;
		}
	}

	@media screen and (max-width: 600px) {

		section.right-panel {
			flex-direction: column;

			.main-layout {

				.current-status {
					width: 100%;
				}
			}

			.side-panel {
				.whos-online {
					display: inline-block;
					margin-right: 25px;
					margin-left: 25px;
	
					.whos {
						display: flex;
						flex-wrap: wrap;
	
						.who {
							margin-right: 10px;
							margin-left: 10px;
							border-bottom: none;
							margin-bottom: 20px;
						}
					}
				}
			}
		}


	}
}

